
import React, { useState, useEffect } from 'react';
import { useLocation ,useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../Utils/Header';
import NoJobsFound from './NoJobsFound';

const SearchResultsPage = ({user}) => {
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  

  const navigate = useNavigate()
  const num = 15000;
  // Extract query parameter from URL
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const query = queryParams.get('query') || '';
  const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

  // Fetch jobs based on search query
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${baseApiUrl}/api/jobs_list`);
        console.log(response.data)
        const filteredJobs = response.data.filter((job) =>
          job.name.toLowerCase().includes(query.toLowerCase()) || job.state_name.toLowerCase().includes(query.toLowerCase()) 
        );
        setJobs(filteredJobs);
      } catch (error) {
        setError('Error fetching jobs');
      } finally {
        setIsLoading(false);
      }
    };
   
    fetchJobs();
  }, [query]);



  if (isLoading) {
    return  (
      <div className="flex sm:flex-row flex-col justify-evenly">
  
      
      <div class="w-full max-w-md mx-auto animate-pulse p-9">
      <p class="h-2 bg-gray-300 rounded-lg w-52 "></p>
  
      <p class="w-48 h-2 mt-6 bg-gray-200 rounded-lg "></p>
      <p class="w-full h-2 mt-4 bg-gray-200 rounded-lg "></p>
      <p class="w-64 h-2 mt-4 bg-gray-200 rounded-lg "></p>
      <p class="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg "></p>
  </div>
      <div class="w-full max-w-md mx-auto animate-pulse p-9">
      <p class="h-2 bg-gray-300 rounded-lg w-52 "></p>
  
      <p class="w-48 h-2 mt-6 bg-gray-200 rounded-lg "></p>
      <p class="w-full h-2 mt-4 bg-gray-200 rounded-lg "></p>
      <p class="w-64 h-2 mt-4 bg-gray-200 rounded-lg "></p>
      <p class="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg "></p>
  </div>
  </div>
    )
  }

  if (error) {
    return <NoJobsFound/>
  }

  if (!jobs.length) {
    return <NoJobsFound/>
  }
  const handleCardClick = (jobId) => {
    if(user){
      navigate(`/candidatedashboard/jobs/apply/${jobId}`)
    }else{

      navigate(`/job/${jobId}`);
    }
  };

  
  return (
    <>
   
      <Header title={"Results for- "} highlight={query} subtitile={"Check out the latest job vacancies."}/>
    <div className="p-4">
      
      <div class="grid sm:grid-cols-2 grid-cols-1 lg:gap-6 gap-4  lg:px-8">
      {jobs.map((job) => (
    <div  key={job.id}
    onClick={() => handleCardClick(job.id)} class="lg:max-w-2xl px-0 lg:px-8 lg:py-4 py-1 bg-[#d9f1f3] rounded-lg shadow-md hover:scale-105 transition-transform ">
    {/* <div class="flex items-center px-2  justify-between">
        <span class="sm:text-sm text-[10px]  font-light text-gray-600 ">{job.max_created_at.slice(0,10)}</span>
        
    </div>  */}

    <div class="mt-2 px-4 sm:px-1">
        <p class="lg:text-xl text-xs sm:font-bold font-semibold text-[#0f3057]  hover:text-gray-600  hover:underline" tabindex="0" role="link">{job.name}</p>
        <div className="flex flex-wrap">
        <div class="flex items-center  mt-1 text-[#11a9b5] ">
            <svg aria-label="suitcase icon" class="sm:w-6 sm:h-6 h-3 w-3   fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 11H10V13H14V11Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7 5V4C7 2.89545 7.89539 2 9 2H15C16.1046 2 17 2.89545 17 4V5H20C21.6569 5 23 6.34314 23 8V18C23 19.6569 21.6569 21 20 21H4C2.34314 21 1 19.6569 1 18V8C1 6.34314 2.34314 5 4 5H7ZM9 4H15V5H9V4ZM4 7C3.44775 7 3 7.44769 3 8V14H21V8C21 7.44769 20.5522 7 20 7H4ZM3 18V16H21V18C21 18.5523 20.5522 19 20 19H4C3.44775 19 3 18.5523 3 18Z"/>
            </svg>

            <h1 class="sm:px-2 px-1  sm:text-sm text-[10px] text-gray-600">Full time </h1>
        </div>
        <div class="flex items-center mt-1 text-[#11a9b5] ">
            <svg aria-label="location pin icon" class="sm:w-6 sm:h-6 h-3 w-3  fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2721 10.2721C16.2721 12.4813 14.4813 14.2721 12.2721 14.2721C10.063 14.2721 8.27214 12.4813 8.27214 10.2721C8.27214 8.063 10.063 6.27214 12.2721 6.27214C14.4813 6.27214 16.2721 8.063 16.2721 10.2721ZM14.2721 10.2721C14.2721 11.3767 13.3767 12.2721 12.2721 12.2721C11.1676 12.2721 10.2721 11.3767 10.2721 10.2721C10.2721 9.16757 11.1676 8.27214 12.2721 8.27214C13.3767 8.27214 14.2721 9.16757 14.2721 10.2721Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M5.79417 16.5183C2.19424 13.0909 2.05438 7.3941 5.48178 3.79418C8.90918 0.194258 14.6059 0.0543983 18.2059 3.48179C21.8058 6.90919 21.9457 12.606 18.5183 16.2059L12.3124 22.7241L5.79417 16.5183ZM17.0698 14.8268L12.243 19.8965L7.17324 15.0698C4.3733 12.404 4.26452 7.9732 6.93028 5.17326C9.59603 2.37332 14.0268 2.26454 16.8268 4.93029C19.6267 7.59604 19.7355 12.0269 17.0698 14.8268Z"/>
            </svg>

            <h1 class="sm:px-2 px-1  sm:text-sm text-[10px] text-gray-600">{job.state_name} , {job.city_name} </h1>
        </div>
        </div>
        <p class="mt-2 text-gray-600  text-[10px] sm:text-sm px-1">{job.job_description?.substring(0, 180)}{" "}  </p>
        {/* <p class="mt-2 text-gray-600  md:hidden text-[10px] px-1 ">{truncatedDescriptionmob}  </p> */}
    </div>
    
    <div class="flex items-center px-2 border-t-2  border-[#0f3057]  sm:px-1 justify-between mt-4">
                      <div class="flex items-center justify-between   text-[#11a9b5] ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          class="sm:w-6 sm:h-6 h-3 w-3   fill-current"
                        >
                          {" "}
                          <path
                            fill="#11a9b5"
                            d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-224c0-35.3-28.7-64-64-64L80 128c-8.8 0-16-7.2-16-16s7.2-16 16-16l368 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L64 32zM416 272a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
                          />
                        </svg>

                        <h1 class="px-2 sm:text-sm text-[13px] text-gray-600">
                          {" "}
                          ₹ {parseInt(job.salarymax)*12} (P.A)
                        </h1>

                      </div>
                      <div class="flex items-center mt-4">
                        <p
                          class="px-4 sm:px-6 sm:py-2 py-1 lg:text-sm text-[13px] sm:font-bold font-semibold mb-2 text-gray-100 transition-colors duration-300 transform bg-[#0f3057] rounded cursor-pointer hover:bg-gray-500"
                          tabindex="0"
                          role="button"
                        >
                          View Job
                        </p>
                      </div>
                    </div>
</div>
  ))}
  </div>
    </div>
    </>
  );
};

export default SearchResultsPage;
