import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
// Create a new instance of axios
const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

const api = axios.create({
  baseURL: baseApiUrl, // replace with your API base URL
});
// Create the auth context
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [username, setusername] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there's a token in localStorage on initial load
    const token = localStorage.getItem('token');
    const userType = localStorage.getItem('userType');
    if (token && userType) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setUser({ userType });
    }
    setLoading(false);
  }, []);

  // Add a response interceptor
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        // Check if the error status is 401 (Unauthorized)
        if (error.response.status === 401) {
          logout(); // Handle unauthorized access
        }
        // Optionally log other errors for debugging
        else if (error.response.status === 500) {
          console.error('Server error:', error.response.data); // Log the server error details
          // Handle 500 error appropriately without logging out
        }
      } else {
        console.error('Network error:', error); // Handle cases with no response
      }
      return Promise.reject(error); // Always reject the promise for further handling
    }
  );

  const login = async (email, password) => {
    try {
      const response = await api.post('/api/candidate/login', { email, password });
      const { token, success, utype } = response.data;
      console.log(response.data)
      if (success) {
        localStorage.setItem('token', token);
        localStorage.setItem('userType', utype);
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        setUser({ utype });
        return { success: true, message: 'Login successful' };
      } else {
        return { success: false, message: 'Login failed' };
      }
    } catch (error) {
      console.error('Login error:', error);
      return { success: false, message: 'An error occurred during login' };
    }
  };
  const loginotp = async (number, otp) => {
    try {
      const response = await api.post(`/api/verify_otp_login?contact=${number}&otp=${otp}`);
      const { token, success, utype } = response.data;
      if (success) {
        localStorage.setItem('token', token);
        localStorage.setItem('userType', utype);
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        setUser({ utype });
        return { success: true, message: 'Login successful' };
      } else {
        return { success: false, message: 'Login failed' };
      }
    } catch (error) {
      console.error('Login error:', error);
      return { success: false, message: 'An error occurred during login' };
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    localStorage.removeItem('mobileNumber');
    setUser(null)
    delete api.defaults.headers.common['Authorization'];
    navigate('/'); 
  };

  const isAuthenticated = () => {
    return !!user;
  };

  const value = {
    user,
    login,
    logout,
    loginotp,
    username,
    setusername,
    isAuthenticated,
    api, // Expose the api instance to components
  };

  if (loading) {
    return <div><Loading/></div>; // Or your custom loading component
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);