import React from "react";
import bg from "../assets/aboutus.png";
import bg2 from "../assets/bg2.png";
import bg3 from "../assets/ab.png";
import bg4 from "../assets/gurditsir.jpeg";
import bg5 from "../assets/gurbaxsir.jpeg";
import Appbanner from "../components/Appbanner";
function AboutUs() {
  

  
  return (
    <section>
      <div class="lg:w-5/6 px-6 py-10 mx-auto">
        <div class="items-center lg:flex">
          <div class="w-full lg:w-1/2">
            <div class="lg:max-w-lg mb-8">
              <h1 class="text-3xl font-semibold text-gray-800  lg:text-4xl">
                About <span class="text-[#11a9b5] ">MySchoolNaukri</span> <br /> <span className="text-xl">  To The Teachers, For the Teachers, By The Teachers  </span>

              </h1>
              <div class="mt-2">
                <span class="inline-block w-40 h-1 bg-[#11a9b5] rounded-full"></span>
                <span class="inline-block w-3 h-1 ml-1 bg-[#11a9b5] rounded-full"></span>
                <span class="inline-block w-1 h-1 ml-1 bg-[#11a9b5] rounded-full"></span>
              </div>

              <p class="mt-3 text-gray-600 text-justify">
              MySchoolNaukri is India's leading career growth platform for teachers, driven by a passion for education and a commitment to empowering educators at every stage of their journey.
              </p>

             
            </div>
           
          </div>

          <div class="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
            <img
              class="w-full h-full lg:max-w-xl"
              src={bg}
              alt="Catalogue-pana.svg"
            />
          </div>
        </div>
      </div>
      <div class="lg:w-5/6 px-6 py-10 mx-auto">
        <div class="items-center lg:flex">
          <div class="w-full lg:w-1/2">
            
            <div class="lg:max-w-lg  ">
              <h1 class="text-3xl font-semibold  text-gray-800  lg:text-4xl">
                Our <span class="text-[#11a9b5] ">Vision</span> 

              </h1>
              <div class="mt-2">
                <span class="inline-block w-40 h-1 bg-[#11a9b5] rounded-full"></span>
                <span class="inline-block w-3 h-1 ml-1 bg-[#11a9b5] rounded-full"></span>
                <span class="inline-block w-1 h-1 ml-1 bg-[#11a9b5] rounded-full"></span>
              </div>

              <p class="mt-3 text-gray-600 text-justify">
              To revolutionize the way teachers find jobs, enhance their skills, and thrive in their careers. We strive to create a vibrant community where educators feel supported, inspired, and equipped to make a meaningful impact on their students and the world
              </p>

             
            </div>
           
          </div>
          <div class="lg:max-w-lg ">
              <h1 class="text-3xl font-semibold  text-gray-800  lg:text-4xl">
                Our <span class="text-[#11a9b5] "> Story:</span> 

              </h1>
              <div class="mt-2">
                <span class="inline-block w-40 h-1 bg-[#11a9b5] rounded-full"></span>
                <span class="inline-block w-3 h-1 ml-1 bg-[#11a9b5] rounded-full"></span>
                <span class="inline-block w-1 h-1 ml-1 bg-[#11a9b5] rounded-full"></span>
              </div>

              <p class="mt-1 text-gray-600 text-justify">
              With over 20 years of experience in the education sector, we deeply understand the unique challenges and opportunities facing teachers in India. MySchoolNaukri was born from a vision to bridge the gap between talented educators and fulfilling career paths, fostering a more vibrant and dynamic educational landscape.              </p>

             
            </div>
          
        </div>
      </div>

      <section class="bg-white ">
        <div class="lg:w-5/6 px-6  mx-auto">
          <div class="lg:flex lg:items-center">
            <div class="hidden lg:flex lg:items-center lg:w-1/2 lg:justify-center">
              <img
                class="w-[20rem] h-[20rem] object-cover xl:w-[34rem] xl:h-[34rem] mt-20 "
                src={bg3}
                alt=""
              />
            </div>
            <div class="w-full py-12 space-y-12 lg:w-1/2 ">
              <div>
                <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl ">
                  What Sets <span className="text-[#11a9b5]">Us </span>   Apart
                </h1>

                <div class="mt-2">
                  <span class="inline-block w-40 h-1 bg-[#11a9b5] rounded-full"></span>
                  <span class="inline-block w-3 h-1 ml-1 bg-[#11a9b5] rounded-full"></span>
                  <span class="inline-block w-1 h-1 ml-1 bg-[#11a9b5] rounded-full"></span>
                </div>
              </div>

              

              <div className="space-y-6">
  
    <div  className="md:flex md:items-start md:-mx-4">
      <span className="inline-block p-2 text-[#11a9b5] bg-blue-100 rounded-xl md:mx-4">
      <svg  className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#11a9b5" d="M352 256c0 22.2-1.2 43.6-3.3 64l-185.3 0c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64l185.3 0c2.2 20.4 3.3 41.8 3.3 64zm28.8-64l123.1 0c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64l-123.1 0c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32l-116.7 0c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0l-176.6 0c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0L18.6 160C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192l123.1 0c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64L8.1 320C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6l176.6 0c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352l116.7 0zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6l116.7 0z"/></svg>
      </span>
      
      <div className="mt-4 md:mx-4 md:mt-0">
        <h1 className="text-xl font-semibold text-gray-700 capitalize">
        India's Largest Teacher Network
        </h1>
        <p className="mt-3 text-gray-500 text-justify">Connect with a vast community of fellow educators, share experiences, and build meaningful professional relationships</p>
      </div>
    </div>
    <div  className="md:flex md:items-start md:-mx-4">
      <span className="inline-block p-2 text-[#11a9b5] bg-blue-100 rounded-xl md:mx-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
          />
        </svg>
      </span>
      
      <div className="mt-4 md:mx-4 md:mt-0">
        <h1 className="text-xl font-semibold text-gray-700 capitalize">
        Personalized Job Matching
        </h1>
        <p className="mt-3 text-gray-500 text-justify">Discover your ideal teaching position through our intelligent algorithm, which tailors recommendations based on your individual qualifications and preferences.</p>
      </div>
    </div>
    <div  className="md:flex md:items-start md:-mx-4">
      <span className="inline-block p-2 text-[#11a9b5] bg-blue-100 rounded-xl md:mx-4">
      <svg className="h-7 w-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64" height="64">
  <g fill="none" stroke="#11a9b5" stroke-width="7" stroke-linecap="round" stroke-linejoin="round">
    <rect x="12" y="20" width="40" height="28" rx="4" ry="4"/>
    <line x1="12" y1="28" x2="52" y2="28"/>
    <line x1="32" y1="20" x2="32" y2="12"/>
    <path d="M28 12h8l-4-4z"/>
  </g>
</svg>
      </span>
      
      <div className="mt-4 md:mx-4 md:mt-0">
        <h1 className="text-xl font-semibold text-gray-700 capitalize">
        Exclusive Upskilling Courses
        </h1>
        <p className="mt-3 text-gray-500 text-justify">Elevate your teaching expertise with our curated collection of professional development courses, designed to keep you at the forefront of educational innovation</p>
      </div>
    </div>
    <div  className="md:flex md:items-start md:-mx-4">
      <span className="inline-block p-2 text-[#11a9b5] bg-blue-100 rounded-xl md:mx-4">
      <svg className="h-7 w-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64" height="64">
  <g fill="none" stroke="#11a9b5" stroke-width="7" stroke-linecap="round" stroke-linejoin="round">
    <rect x="16" y="20" width="32" height="24" rx="4" ry="4"/>
    <line x1="24" y1="16" x2="24" y2="20"/>
    <line x1="40" y1="16" x2="40" y2="20"/>
    <polygon points="14,36 22,30 14,24 14,36"/>
    <polygon points="50,36 42,30 50,24 50,36"/>
  </g>
</svg>
      </span>
      
      <div className="mt-4 md:mx-4 md:mt-0">
        <h1 className="text-xl font-semibold text-gray-700 capitalize">
        Rapid Job Placement
        </h1>
        <p className="mt-3 text-gray-500 text-justify">Find your dream job faster with our streamlined application process and direct connections to top schools across India</p>
      </div>
    </div>
    <div  className="md:flex md:items-start md:-mx-4">
      <span className="inline-block p-2 text-[#11a9b5] bg-blue-100 rounded-xl md:mx-4">
      <svg className="h-7 w-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64" height="64">
  <g fill="none" stroke="#11a9b5" stroke-width="7" stroke-linecap="round" stroke-linejoin="round">
    <path d="M48 40v-8a16 16 0 0 0-32 0v8"/>
    <rect x="12" y="40" width="10" height="12" rx="2" ry="2"/>
    <rect x="42" y="40" width="10" height="12" rx="2" ry="2"/>
    <line x1="22" y1="46" x2="42" y2="46"/>
  </g>
</svg>
      </span>
      
      <div className="mt-4 md:mx-4 md:mt-0">
        <h1 className="text-xl font-semibold text-gray-700 capitalize">
        Dedicated Support
        </h1>
        <p className="mt-3 text-gray-500 text-justify">Our team of education experts is committed to providing personalized support and guidance throughout your career journey</p>
      </div>
    </div>

</div>
            </div>
          </div>

          <hr class="my-12 border-gray-200 " />

          <div class="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5"></div>
        </div>
      </section>
      <section class="bg-white ">
        <div class="lg:w-5/6 px-6  mx-auto">
          <div class="lg:flex lg:items-center">
            
            <div class="w-full py-12 space-y-12 lg:w-1/2 ">
              <div>
                <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl ">
                Our  <span className="text-[#11a9b5]">Values: </span>  
                </h1>

                <div class="mt-2">
                  <span class="inline-block w-40 h-1 bg-[#11a9b5] rounded-full"></span>
                  <span class="inline-block w-3 h-1 ml-1 bg-[#11a9b5] rounded-full"></span>
                  <span class="inline-block w-1 h-1 ml-1 bg-[#11a9b5] rounded-full"></span>
                </div>
              </div>

              

              <div className="space-y-6">
  
    <div  className="md:flex md:items-start md:-mx-4">
      <span className="inline-block p-2 text-[#11a9b5] bg-blue-100 rounded-xl md:mx-4">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" class="w-7 h-7" fill="none" stroke="#11a9b5" stroke-width="7" stroke-linecap="round" stroke-linejoin="round">
  <circle cx="32" cy="32" r="16" />
  <path d="M32 12 v-8 M32 60 v-8 M52 32 h8 M4 32 h8 M45.25 18.75 l5.66 -5.66 M13.1 50.9 l5.66 -5.66 M18.75 18.75 l-5.66 -5.66 M50.9 50.9 l-5.66 -5.66" />
</svg>
      </span>

      <div className="mt-4 md:mx-4 md:mt-0">
        <h1 className="text-xl font-semibold text-gray-700 capitalize">
        Empowerment
        </h1>
        <p className="mt-3 text-gray-500 text-justify">We believe in empowering teachers with the tools, resources, and knowledge they need to reach their full potential</p>
      </div>
    </div>
    <div  className="md:flex md:items-start md:-mx-4">
      <span className="inline-block p-2 text-[#11a9b5] bg-blue-100 rounded-xl md:mx-4">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" class="w-7 h-7" fill="none" stroke="#11a9b5" stroke-width="7" stroke-linecap="round" stroke-linejoin="round">
  <path d="M32 12 v10 l-8 8 v6 l8 -4 v12" />
  <circle cx="32" cy="32" r="20" />
</svg>

      </span>

      <div className="mt-4 md:mx-4 md:mt-0">
        <h1 className="text-xl font-semibold text-gray-700 capitalize">
        Innovation
        </h1>
        <p className="mt-3 text-gray-500 text-justify">We embrace innovation and technology to create cutting-edge solutions that transform the education landscape.</p>
      </div>
    </div>
    <div  className="md:flex md:items-start md:-mx-4">
      <span className="inline-block p-2 text-[#11a9b5] bg-blue-100 rounded-xl md:mx-4">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" class="w-7 h-7" fill="none" stroke="#11a9b5" stroke-width="7" stroke-linecap="round" stroke-linejoin="round">
  <circle cx="32" cy="32" r="16" />
  <circle cx="16" cy="32" r="8" />
  <circle cx="48" cy="32" r="8" />
</svg>

      </span>

      <div className="mt-4 md:mx-4 md:mt-0">
        <h1 className="text-xl font-semibold text-gray-700 capitalize">
        Community
        </h1>
        <p className="mt-3 text-gray-500 text-justify">We foster a supportive and collaborative community where educators can connect, share, and learn from each other.</p>
      </div>
    </div>
    <div  className="md:flex md:items-start md:-mx-4">
      <span className="inline-block p-2 text-[#11a9b5] bg-blue-100 rounded-xl md:mx-4">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" class="w-7 h-7" fill="none" stroke="#11a9b5" stroke-width="7" stroke-linecap="round" stroke-linejoin="round">
  <polygon points="32 12 40 32 24 32 32 12" fill="none"/>
  <circle cx="32" cy="32" r="20" />
  <path d="M32 32 v16" />
</svg>

      </span>

      <div className="mt-4 md:mx-4 md:mt-0">
        <h1 className="text-xl font-semibold text-gray-700 capitalize">
        Excellence
        </h1>
        <p className="mt-3 text-gray-500 text-justify">We are committed to delivering the highest standards of quality and service in everything we do</p>
      </div>
    </div>
 
</div>

            </div>
            <div class="hidden lg:flex lg:items-center lg:w-1/2 lg:justify-center">
              <img
                class="w-[20rem] h-[20rem] object-cover xl:w-[34rem] xl:h-[34rem] rounded-full"
                src={bg2}
                alt=""
              />
            </div>
          </div>

          <hr class="my-12 border-gray-200 " />

          <div class="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5"></div>
        </div>
      </section>
      <Appbanner/>

      <section class="bg-white ">
    <div class="lg:w-5/6 px-6 py-20 mx-auto">
        <div class="xl:flex xl:items-center xL:-mx-4">
            <div class="xl:w-1/2 xl:mx-4">
                <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl ">Meet the  <span className="text-[#11a9b5]">Founders </span> </h1>
                <div class="mt-2">
                  <span class="inline-block w-40 h-1 bg-[#11a9b5] rounded-full"></span>
                  <span class="inline-block w-3 h-1 ml-1 bg-[#11a9b5] rounded-full"></span>
                  <span class="inline-block w-1 h-1 ml-1 bg-[#11a9b5] rounded-full"></span>
                </div>
                <p class="max-w-2xl mt-4 text-gray-500 text-justify">
                Our founders bring over 20 years of expertise in school establishment and management consultancy. Alumni of prestigious institutions such as IIT(BHU) Varanasi and the Indian School of Business, they are driven by a passion to help schools deliver quality education nationwide..
                </p>
            </div>

            <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-0 xl:mx-4 xl:w-1/2 md:grid-cols-2">
                <div>
                    <img class="object-cover rounded-xl aspect-square" src={bg5} alt=""/>

                    <h1 class="mt-4 text-2xl font-semibold text-gray-700 capitalize :text-white">Gurbax Singh Chhabda</h1>

                    <p class="mt-2 text-gray-500 capitalize :text-gray-300">Co-founder</p>
                </div>

                <div>
                <img class="object-cover rounded-xl aspect-square" src={bg4} alt=""/>

                    <h1 class="mt-4 text-2xl font-semibold text-gray-700 capitalize :text-white">Gurdit Singh Chhabda</h1>

                    <p class="mt-2 text-gray-500 capitalize :text-gray-300">Co-founder</p>
                </div>
            </div>
        </div>
    </div>
</section>

    </section>
  );
}

export default AboutUs;
