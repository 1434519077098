

import { useState } from "react"
import { Link } from "react-router-dom"
import logo from '../assets/last.png'
// import logo2 from '../assets/webheaderlogo.png'

import logo1 from '../assets/flogo1.jpeg'
import LoginScholl from "../Utils/LoginScholl"
import Loginmodal from "../Utils/Loginmodal"

export default function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isslogin, setIschoollogin] = useState(false); //for school login modal
  const [istlogin, setteacherlogin] = useState(false); //for teacher login model
  // const token = localStorage.getItem('token')
  const studentlogin = () => setIschoollogin(true);
  const teacherlogin = () => setteacherlogin(true);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // smooth scrolling
    });
  }

  const navItems = [
    { name: "Home", href: "/" },
    { name: "Find jobs", href: "/all-jobs" },
    { name: "About Us", href: "/about" },
    { name: "Contact Us", href: "/contact" },
    // { name: "Courses", href: "/courses" },
  ]

  return (
    <nav className="bg-[#0f3057] shadow-sm fixed w-full z-20 py-2 ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center  w-1/6 ">
            <Link href="/" className="flex-shrink-0   flex items-center">
             <img
              className=" md:hidden lg:block w-1/4  " 
              src={logo}
              alt="Logo"
            /> 
            </Link>
            <Link href="/" className="flex-shrink-0   flex items-center">
            <img
              className="w-full lg:hidden hidden md:block lg:px-20 h-6 sm:h-[40px]"
              src={logo1}
              alt="Logo"
            />
            </Link>
          </div>
          <div className="hidden sm:flex sm:items-center sm:justify-center flex-1">
            <div className="flex md:space-x-0 lg:space-x-4">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  onClick={scrollToTop}
                  to={item.href}
                  className=" sm:text-white text-black  hover:bg-[#11a9b5]  px-3 py-2 rounded-md text-md font-medium"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
          {/* {token ?   <Link className="hidden sm:flex items-center" to='candidatedashboard/course'>
           <button
              className="inline-flex items-center justify-center px-4 py-2 border text-white  border-transparent text-sm font-medium bg-[#11a9b5]  rounded-3xl hover:bg-white hover:text-black  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
              >
              Dashboard
            </button>
              </Link>
             : 
          <div className="hidden sm:flex sm:items-center">
            <button
              onClick={studentlogin}
              className="inline-flex items-center justify-center px-4 py-2 border text-white  border-transparent text-sm font-medium bg-[#11a9b5]  rounded-3xl hover:bg-white hover:text-black  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
            >
              School Login
            </button>
            <button
              onClick={teacherlogin}
              className="inline-flex items-center justify-center px-4 py-2 border text-white border-transparent text-sm font-medium bg-[#11a9b5]  rounded-3xl hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Teacher Login
            </button>
          </div>
           } */}
            <div className="hidden sm:flex sm:items-center">
            <button
              onClick={studentlogin}
              className="inline-flex items-center justify-center px-4 py-2 border text-white  border-transparent text-sm font-medium bg-[#11a9b5]  rounded-3xl hover:bg-white hover:text-black  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
            >
              School Login
            </button>
            <button
              onClick={teacherlogin}
              className="inline-flex items-center justify-center px-4 py-2 border text-white border-transparent text-sm font-medium bg-[#11a9b5]  rounded-3xl hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Teacher Login
            </button>
          </div>
          <div className="flex items-center  sm:hidden">
            <button
              onClick={toggleMobileMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-white bg-[#0f3057]   "
            >
              <span className="sr-only">Open main menu</span>
              {isMobileMenuOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="sm:hidden min-h-screen ">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {navItems.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                onClick={toggleMobileMenu}
                className="text-white  hover:text-gray-900 flex flex-col w-fit  px-3 py-2 rounded-md text-base font-medium"
              >
                {item.name}
              </Link>
            ))}
          </div>
        
          <div className="pt-4 pb-3 border-t border-gray-200">
            <div className="px-2 space-y-1">
            <button
              
              onClick={studentlogin}
              className="inline-flex items-center justify-center px-4 py-2 border text-white  border-transparent text-sm font-medium bg-[#11a9b5]  rounded-3xl hover:bg-white hover:text-black  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
              >
              School Login
            </button>
              <button
              onClick={teacherlogin}
              className="inline-flex items-center justify-center px-4 py-2 border text-white border-transparent text-sm font-medium bg-[#11a9b5]  rounded-3xl hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
              Teacher Login
            </button>
            </div>
          </div>
            
        </div> 
      )}
     
      {isslogin && <LoginScholl setIschoollogin={setIschoollogin} logo1={logo1}  />}
      {istlogin && <Loginmodal setteacherlogin={setteacherlogin} logo1={logo1} />}      
    </nav>
  )
}