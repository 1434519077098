import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../Utils/Context";
import Loading from "../../Utils/Loading";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from '../../Utils/Header'
import SubscriptionPlan from "../components/Currentplan";
import Buyplan from "../components/Buyplan";

function Candisuncription() {
         const [loading, setloading] = useState(false);
         const [show, setshow] = useState(false);
        const {api} = useAuth()
        // const token = localStorage.getItem("token");
        const fetchpackages = async (url) => {
            const token = localStorage.getItem("token");
            try {
              const response = await api.get(
                `${url}`,
                {}, // Post body, if required
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
          
              return response.data; // Return the data property from Axios response
            } catch (error) {
              console.error("Failed to fetch candidates:", error);
              throw new Error("Failed to fetch candidates");
            }
          };
          
      // Fetch data with React Query and pass page as a dependency
      const { data, isLoading, isError  , error} = useQuery({queryKey:["packages"], queryFn:() => fetchpackages('/api/employer/pricing_list'), 
        keepPreviousData: true, // Retain previous page data while loading new page
      });
      const { data:subactive=[]} = useQuery({queryKey:["activepackage"], queryFn:() => fetchpackages('/api/employer/active_subscription'), 
        keepPreviousData: true, // Retain previous page data while loading new page
      });
      // Handle loading and error states
      if (isLoading) return <Loading/>;
      if (isError) return <div className='text-center text-2xl text-red-500'>Error: {error.message}</div>;
  return (
<>
    <section class="text-gray-600 body-font overflow-hidden">
        <div className="flex mt-4">
        <button type="button" onClick={()=>setshow(false)} class="text-white bg-blue-950 hover:bg-blue-800 focus:outline-none  font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 ">Current Subscription</button>
        <button type="button" onClick={()=>setshow(true)} class="text-white bg-blue-950 hover:bg-blue-800 focus:outline-none  font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 ">Buy Subcription</button>
        </div>
        {
            show ? 
          <Buyplan data={data}/>
         : <SubscriptionPlan subactive={subactive}/>
        
        }

  </section>
</>  )
}

export default Candisuncription