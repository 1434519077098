import React, { useState, useEffect } from 'react';
import { useAuth } from '../Utils/Context';
import { Link } from "react-router-dom";
import user from '../assets/user.png'
import Jobpostmodal from "./components/Jobpostmodal";
import { fetchschooldata } from './components/Options';
import SubscriptionModal from './components/Stepmodal';
function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

  const [schoolData, setSchoolData] = useState([]); 
  const apiUrl = `${baseApiUrl}/api/view_school`; // Replace with your actual API URL
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModaltepOpen, setstepModalOpen] = useState(false);

  useEffect(() => {
   const getSchoolData = async () => {
     try {
       const data = await fetchschooldata(apiUrl);
       // console.log('Fetched school data:', data); // Debug the fetched data
       if(data){
        console.log(data[0].label)
         setSchoolData(data || []); // Set the data (if null, default to empty array)
        
        }
        if(data[0].label === undefined){
          setstepModalOpen(true)
          console.log('haa')
        }else{
          setstepModalOpen(false)
        }
      // Stop loading
     } catch (err) {
       console.error('Error fetching data:', err);
      ; // Set error message
        // Stop loading
     }
   };
   getSchoolData();
 }, [apiUrl]);
 const closeModal = () => {
  setIsModalOpen(false); // Close the modal
  // Clear job data
};
 const openModal = () => {
  setIsModalOpen(true); // Close the modal
  // Clear job data
};
const {logout} = useAuth()

const loginingout = ()=>{
logout();
//  navigate('/')

}
const closemodal = () => {
  setstepModalOpen(false) // Close the modal
  // Clear job data
};  return (
    <nav className="bg-[#11a9b5] shadow-sm fixed w-full z-40 py-2 ">
      <div className=" mx-auto px-0 sm:px-4 lg:px-0">
        <div className="flex sm:justify-between  h-16">
          <button className="relative sm:hidden  flex mx-4 z-50 items-center px-2 py-2 border  text-white border-transparent text-sm font-medium   rounded-3xl hover:text-black   ">
            <img
              class="object-cover w-12 h-12 rounded-full"
              src={user}
              alt=""
              loading="lazy"
            />
          </button>
          <h2 className="text-white sm:text-xl text-lg mt-4 font-bold sm:ml-[19rem]">
            {schoolData[0]?.label}
          </h2>

          <div className="hidden sm:flex gap-x-3 px-4 sm:items-center">
            {isModalOpen&&(


               <Jobpostmodal closeModal={closeModal}/> 
              
            )}
            
             <button 
        onClick={openModal}
        className="px-4 sm:px-4 sm:py-2 py-1 lg:text-sm text-[14px] sm:font-bold font-semibold mb-2 text-gray-100 transition-colors duration-300 transform bg-[#0f3057] rounded-2xl cursor-pointer hover:bg-gray-500"
      >
        
        Post job
      </button>
            {/* Dropdown toggle button */}
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="relative flex z-50 items-center px-2 py-2 border text-white border-transparent text-sm font-medium   rounded-3xl hover:text-black   "
            >
              <img
                class="object-cover w-12 h-12 rounded-full"
               src={user}
                alt=""
                loading="lazy"
              />
              <svg
                className="w-5 h-5 mx-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z"
                  fill="currentColor"
                />
              </svg>
            </button>

            {/* Dropdown menu */}
            {isOpen && (
              <div
                className="absolute right-0 z-60 sm:w-56 py-2 mt-2 sm:top-20 bg-white rounded-md shadow-xl "
                onClick={() => setIsOpen(false)}
              >
                <Link
                 to="profile" 
                  className="flex items-center p-3 text-sm text-gray-800 transition-colors duration-300 transform  hover:bg-gray-300  "
                >
                  <svg
                    className="w-5 h-5 mx-1"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8C17 10.7614 14.7614 13 12 13C9.23858 13 7 10.7614 7 8ZM12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z"
                      fill="currentColor"
                    />
                    <path
                      d="M6.34315 16.3431C4.84285 17.8434 4 19.8783 4 22H6C6 20.4087 6.63214 18.8826 7.75736 17.7574C8.88258 16.6321 10.4087 16 12 16C13.5913 16 15.1174 16.6321 16.2426 17.7574C17.3679 18.8826 18 20.4087 18 22H20C20 19.8783 19.1571 17.8434 17.6569 16.3431C16.1566 14.8429 14.1217 14 12 14C9.87827 14 7.84344 14.8429 6.34315 16.3431Z"
                      fill="currentColor"
                    />
                  </svg>
                  <span className="mx-1">View Profile</span>
                </Link>

                <Link
                  onClick={loginingout}
                  className="flex items-center p-3 text-sm text-gray-800 transition-colors duration-300 transform  hover:bg-gray-300 "
                >
                  <svg
                    className="w-5 h-5 mx-1"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 19H3C1.89543 19 1 18.1046 1 17V16H3V7C3 5.89543 3.89543 5 5 5H19C20.1046 5 21 5.89543 21 7V16H23V17C23 18.1046 22.1046 19 21 19ZM5 7V16H19V7H5Z"
                      fill="currentColor"
                    />
                  </svg>
                  <span className="mx-1">Log Out</span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      { isModaltepOpen && <SubscriptionModal isOpen={isModaltepOpen}  onClose={closemodal}/>}
    </nav>
  );
}

export default Navbar;
