import React, { useState, useEffect } from 'react';
import { Controller , useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from "../../Utils/Context";
import { Designation, Education, Location, fetchschooldata, Skills, Specilization } from './Options';
import { customClassNames,customStyles } from "./Options";
import VirtualizedMenuList from "../../Utils/VirtualizedMenuList";
const Jobpostmodal = ({title ,jobData,closeModal,openModal}) => {
  // Modal state to show/hide the modal
  const {api} = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Form state
  const { register, handleSubmit, setValue,reset,control ,    formState: { errors }} = useForm();

  const [issLoading, setIsLoading] = useState(false);
  const [draft, setIsDraft] = useState(false);
  const [schoolData, setSchoolData] = useState([]); 
  const[loading,setloading]=useState(false)
  const { options, isLoading } = Location();
  const { options2, isLoading2 } = Specilization();
  const { options3, isLoading3 } = Designation();
  const { options5, isLoading5 } = Skills();
  const { options4, isLoading4 } = Education();
  const baseApiUrl = process.env.REACT_APP_BASE_API_URL;


 // Replace with your actual API URL

 const apiUrl = `${baseApiUrl}/api/view_school`; // Replace with your actual API URL

 useEffect(() => {
  const getSchoolData = async () => {
    try {
      const data = await fetchschooldata(apiUrl);
      // console.log('Fetched school data:', data); // Debug the fetched data
      setSchoolData(data ); // Set the data (if null, default to empty array)
     // Stop loading
    } catch (err) {
      console.error('Error fetching data:', err);
     ; // Set error message
       // Stop loading
    }
  };

  getSchoolData();
}, [apiUrl]);
   
useEffect(() => {
  if (jobData && schoolData) {
    // Use reset to populate the form with fetched data
    reset({
      selectschool: schoolData.find(
        (option) => option.value === jobData.school_name
      ) || null,
      selectdesignation: options3?.find(
        (option) => option.value === jobData.designation
      ) || null,
      selectspecilization: options2?.find(
        (option) => option.value === jobData.specilization
      ) || null,
      positions: jobData.openings || "",
      category: jobData.category || "",
      job_description: jobData.job_description || "",
      experience: jobData.experience || "",
      minSalary: jobData.salarymin || "",
      maxSalary: jobData.salarymax || "",
      key_skills: options4?.find(
        (option) => option.value === jobData.key_skills
      ) || null,
      employment_type: EmplyomentOptions?.find(
        (option) => option.value === jobData.employment_type
      ) || null,
      selecteducation: options5?.find(
        (option) => option.value === jobData.qualifications
      ) || null,
    
      selectedDate: jobData.joining_date || "",
      board: jobData.board || "",
      city: options?.find(
        (option) => option.value === jobData.location
      ) || null,
    });
  }
}, [jobData, reset,options4,options5]);
  
  const handleSelectChange = (option, name) => {
    
    setValue(name, option ? option.value : null);
  };

  // const token = localStorage.getItem('token');
  // console.log(token)
 
  const onSubmit = async (data) => {
    setIsLoading(true);
  
    // Prepare submit data
    const submitData = {
      selectschool: data.selectschool?.value || null,
      selectdesignation: data.selectdesignation?.value || null,
      selectspecilization: data.selectspecilization?.value || null,
      employment_type: data.employment_type?.value || null,
      key_skills: data.key_skills?.value || null,
      selecteducation: data.selecteducation?.value || null,
      city: data.city?.value || null,
      selectedDate: data.selectedDate,
      board: data.board,
      maxSalary: data.maxSalary,
      minSalary: data.minSalary,
      experience: data.experience,
      job_description: data.job_description,
      positions: data.positions,
      category: data.category,
      draft: data.draft ? 1 : 0 // Ensure draft is a number
    };
  
    const token = localStorage.getItem('token'); // Get bearer token from localStorage
    
    try {
  
      // Check if jobData exists to decide on API endpoint
      if (jobData) {
        // Update job API call
        const response = await api.post(`/api/updatejob?jobId=${jobData.id}`, submitData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.data.Success) {  
          toast.success(response.data.Success);
          console.log('Update Response:', response.data);
          closeModal()
        } else {
          toast.error('Update failed');
        }
      } else {
        // Post job API call
        const response = await api.post('/api/postjob', submitData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.data.Success) {  
          toast.success(response.data.Success);
          console.log('Post Response:', response.data);
          closeModal()
        } else {
          toast.error('Post failed');
        }
      }
    } catch (error) {
      toast.error('Error occurred while submitting: ' + (error.response?.data?.message || error.message));
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
      closeModal()

    }
  };
  const EmplyomentOptions = [
    { value: '28ea843c-44e0-4768-94a6-8b5c23737b7d ', label: 'Remote' },
    { value: '527e408c-bde0-4a9b-9101-9e84d20ed77c', label: 'Full Time' },
    { value: 'b8b7ed11-9611-4712-a2e2-4a72b1c66512', label: 'Part time' },
    { value: 'c7b670fc-a641-4903-9728-550fbbd07e55', label: 'In Office' },
  ];
  // // Open modal
  // const openModal = () => setIsModalOpen(true);

  // // Close modal
  // const closeModal = () => setIsModalOpen(false);


  return (
    <>
      {/* Button to toggle modal */}
      {/* <button 
        onClick={openModal}
        className="px-4 sm:px-4 sm:py-2 py-1 lg:text-sm text-[14px] sm:font-bold font-semibold mb-2 text-gray-100 transition-colors duration-300 transform bg-[#0f3057] rounded cursor-pointer hover:bg-gray-500"
      >
        
        {title}
      </button> */}

      {/* Modal */}
      
        <div className="fixed  inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-900 bg-opacity-50">
          <div className="relative  p-4 w-full max-w-2xl max-h-[90vh]">
            <div className="relative bg-white rounded-lg shadow  overflow-y-auto max-h-[90vh]">
              
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                <h3 className="text-lg font-bold text-[#11a9b5]">
                  Create New Job Post
                </h3>
                <button 
                  onClick={closeModal}
                  type="button" 
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
                >
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"/>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>


              <form onSubmit={handleSubmit(onSubmit)} className="p-4 md:p-5">
      <div className="grid gap-4 mb-4 grid-cols-2">
        {/* School Name */}
        {/* <div className="col-span-2">
          <label htmlFor="selectschool" className="block mb-2 text-sm font-medium text-gray-900">School Name</label>
          <input 
            type="text" 
            {...register('selectschool', { required: true })} 
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" 
            placeholder="Type school name" 
          />
        </div> */}
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="selectschool" className="block mb-2 text-sm font-medium text-gray-900">select school</label>
          {/* <Select
            onChange={(option) => handleSelectChange(option, 'selectschool')}
            // Add necessary props here for react-select
            placeholder="Select school"
            isClearable
            styles={customStyles}
            className="w-full"
            isLoading={loading}
            classNamePrefix="react-select"
            classNames={customClassNames}       
            options={schoolData}
            components={{ MenuList: VirtualizedMenuList }}
          /> */}
          <Controller
        name="selectschool"
        rules={ { required: true }}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            onChange={(option) => {
              field.onChange(option);
              // handleSelectChange(option, 'selectschool');
            }}
            placeholder="Select school"
            isClearable
            styles={customStyles}
            className="w-full"
            isLoading={false} // or any loading state
            options={schoolData}
          />
        )}
      />
        </div>

        {/* Designation */}
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="selectdesignation" className="block mb-2 text-sm font-medium text-gray-900">Designation</label>
          <Controller
        name="selectdesignation"
        control={control}
        rules={ { required: true }}
        render={({ field }) => (
          <Select
          {...field}
          onChange={(option) => {
            field.onChange(option);
            // handleSelectChange(option, 'selectdesignation');
          }}
           
            // Add necessary props here for react-select
            placeholder="Select Designation"
            isClearable
            styles={customStyles}
            className="w-full"
            classNamePrefix="react-select"
            classNames={customClassNames}       
            options={options3}
            isLoading={isLoading3}
            components={{ MenuList: VirtualizedMenuList }}
          />
        )}
      />
          
        </div>

        {/* Specialization */}
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="selectspecilization" className="block mb-2 text-sm font-medium text-gray-900">Specialization</label>
          <Controller
        name="selectspecilization"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            onChange={(option) => {
              field.onChange(option);
              // handleSelectChange(option, 'selectspecilization');
            }}
            placeholder="Select Specialization"
            isClearable
            styles={customStyles}
            className="w-full"
            classNamePrefix="react-select"
            classNames={customClassNames}       
            options={options2}
            isLoading={isLoading2}
            components={{ MenuList: VirtualizedMenuList }}
          />
        )}
      />
          
        </div>
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="positions" className="block mb-2 text-sm font-medium text-gray-900">Number of Positions</label>
          <input
            type="number"
            {...register('positions', { required: true, min: 1 })}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
            placeholder="Enter number of positions"
            min="1"
          />
        </div>
        {/* Category */}
        <div className="col-span-2">
          <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900">Category</label>
          <ul className="items-center py-2 w-full text-sm font-medium text-gray-900 bg-white sm:border border-gray-200 rounded-lg flex justify-center">
            <li className="w-full border-gray-200  sm:border-r">
              <input type="radio" value="TGT"        
                   {...register('category')} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
              <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900">TGT</label>
            </li>
            <li className="w-full border-gray-200   sm:border-r">
              <input type="radio" value="PRT" {...register('category')} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
              <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900">PRT</label>
            </li>
            <li className="w-full  border-gray-200   sm:border-r">
              <input type="radio" value="PGT" {...register('category')} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
              <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900">PGT</label>
            </li>
            <li className="w-full  border-gray-200  sm:border-r">
              <input type="radio" value="NTT" {...register('category')} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
              <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900">NTT</label>
            </li>
          </ul>
          <ul className='items-center w-full text-sm font-medium text-gray-900 bg-white sm:border border-gray-200 rounded-lg flex justify-center'>

  
<li class="w-full  border-gray-200 sm:border-b sm:border-r ">
    <div class="flex items-center ps-3">
        <input  type="radio" value="Principal"  {...register('category')}  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 "/>
        <label for="horizontal-list-radio-id" class="w-full py-3 ms-2 text-sm font-medium text-gray-900 ">Principal</label>
    </div>
</li>
<li class="w-full  border-gray-200 sm:border-b sm:border-r ">
    <div class="flex items-center ps-3 ">
        <input type="radio" value="Vice Principal" {...register('category')}  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 "/>
        <label for="horizontal-list-radio-military" class="w-full py-3 ms-2 text-sm font-medium text-gray-900 ">Vice Principal</label>
    </div>
</li>
<li class="w-full  border-gray-200 sm:border-b  sm:border-r">
    <div class="flex items-center ps-3">
        <input  type="radio" value="All" {...register('category')}  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 "/>
        <label for="horizontal-list-radio-passport" class="w-full py-3 ms-2 text-sm font-medium text-gray-900 ">All</label>
    </div>
</li>
</ul>
        </div>

        {/* Job Description */}
        <div className="col-span-2">
          <label htmlFor="job_description" className="block mb-2 text-sm font-medium text-gray-900">Job Description</label>
          <textarea
            {...register('job_description', {
              required: "this feild is required"})}
            rules={{ required: "This field is required" }}
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
            placeholder="Write job description"
          ></textarea>
        </div>

        {/* Experience */}
        <div className="col-span-1">
          <label htmlFor="experience" className="block mb-2 text-sm font-medium text-gray-900">Experience</label>
          <input 
            type="number" 
            {...register('experience')} 
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            placeholder="Experience"
          />
        </div>

        {/* Salary */}
        <div className="col-span-1">
          <label htmlFor="minSalary" className="block mb-2 text-sm font-medium text-gray-900">Min Salary</label>
          <input 
            type="number" 
            {...register('minSalary' , {
              required: "this feild is required"})} 
            rules={{ required: "This field is required" }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
            placeholder="Min Salary"
          />
        </div>
        <div className="col-span-1">
          <label htmlFor="maxSalary" className="block mb-2 text-sm font-medium text-gray-900">Max Salary</label>
          <input 
            type="number" 
            {...register('maxSalary', {
              required: "this feild is required"})} 
            rules={{ required: "This field is required" }}

            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
            placeholder="Max Salary"
          />
        </div>

        {/* Key Skills */}
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="key_skills" className="block mb-2 text-sm font-medium text-gray-900">Key Skills</label>
          <Controller
        name="key_skills"
        control={control}
        render={({ field }) => (
          <Select
          {...field}
          onChange={(option) => {
            field.onChange(option);
            // handleSelectChange(option, 'key_skills');
          }}
           
            // Add necessary props here for react-select
            placeholder="Search Skills"
            isClearable
            styles={customStyles}
            className="w-full"
            classNamePrefix="react-select"
            classNames={customClassNames}       
            options={options4}
            isLoading={isLoading4}
            components={{ MenuList: VirtualizedMenuList }}
            />
        )}
      />
         
      
        </div>

        {/* Employment Type */}
      
        <div className="col-span-2">
          <label htmlFor="employment_type" className="block mb-2 text-sm font-medium text-gray-900">Employment type </label>
          <Controller
        name="employment_type"
        control={control}
        render={({ field }) => (
          <Select
          {...field}
          onChange={(option) => {
            field.onChange(option);
            // handleSelectChange(option, 'employment_type');
          }}
           
            // Add necessary props here for react-select
            placeholder="Select Employment"
            isClearable
            styles={customStyles}
            className="w-full"
            classNamePrefix="react-select"
            classNames={customClassNames}       
            options={EmplyomentOptions}
            components={{ MenuList: VirtualizedMenuList }}
            />
        )}
      />
          
        </div>
        

        {/* Education */}
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="selecteducation" className="block mb-2 text-sm font-medium text-gray-900">Education</label>
          <Controller
        name="selecteducation"
        control={control}
        render={({ field }) => (
          <Select
          {...field}
          onChange={(option) => {
            field.onChange(option);
            // handleSelectChange(option, 'selecteducation');
          }}
           
            // Add necessary props here for react-select
            placeholder="Select Education"
            isClearable
            styles={customStyles}
            className="w-full"
            classNamePrefix="react-select"
            classNames={customClassNames}       
            options={options5}
            isLoading={isLoading5}
            components={{ MenuList: VirtualizedMenuList }}
            />
        )}
      />
          
        </div>

        {/* City */}
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900">Select City</label>
          <Controller
        name="city"
        control={control}
        rules={{ required: "This field is required" }}

        render={({ field }) => (
          <Select
          {...field}
          onChange={(option) => {
            field.onChange(option);
            // handleSelectChange(option, 'city');
          }}
           
            // Add necessary props here for react-select
            placeholder="Select City"
            isClearable
            styles={customStyles}
            className="w-full"
            classNamePrefix="react-select"
            classNames={customClassNames}       
            options={options}
            isLoading={isLoading}
            components={{ MenuList: VirtualizedMenuList }}
            />
        )}
      />
         
        </div>

        {/* Joining Date */}
        <div className="col-span-2">
          <label htmlFor="selectedDate" className="block text-sm font-medium text-gray-900">Joining Date</label>
          <input 
            type="date" 
            {...register('selectedDate')}
            className="bg-gray-50 border border-gray-300 rounded-lg block w-full p-2.5"
          />
        </div>

        {/* Board */}
        <div className="col-span-2 ">
          <label htmlFor="board" className="block mb-2 text-sm font-medium text-gray-900">Board</label>
          <ul className="flex gap-4">
            <li>
              <input type="radio" value="CBSE" {...register('board', {
                    required: "Board is required"})} className="w-4 h-4" />
              <label className="ms-2">CBSE</label>
            </li>
            <li>
              <input type="radio" value="ICSE" {...register('board')} className="w-4 h-4" />
              <label className="ms-2">ICSE</label>
            </li>
            <li>
              <input type="radio" value="STATE" {...register('board')} className="w-4 h-4" />
              <label className="ms-2">STATE</label>
            </li>
            <li>
              <input type="radio" value="IB" {...register('board')} className="w-4 h-4" />
              <label className="ms-2">IB</label>
            </li>
          </ul>
          {errors.board && (
                  <p className="text-red-600 text-sm">
                    {errors.board.message}
                  </p>
                )}
        </div>
      </div>

      {/* Submit Button */}
       {/* Submit and Save Draft Buttons */}
       <div className="flex justify-end gap-4">
        <button
          type="button"
          className="w-full sm:w-auto text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 rounded-lg text-sm px-5 py-2.5"
          disabled={issLoading}
          onClick={closeModal}
        >
         Cancel
        </button>
        <button
          type="button"
          className="w-full sm:w-auto text-white bg-green-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 rounded-lg text-sm px-5 py-2.5"
          disabled={issLoading}
          onClick={() => {
            setIsDraft(true); // Set isDraft to true for saving draft
            handleSubmit(onSubmit)();
          }}
        >
          {issLoading ? 'Saving Draft...' : 'Save Draft'}
        </button>

        <button
          type="submit"
          className="w-full sm:w-auto text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-5 py-2.5"
          disabled={issLoading}
          onClick={() => setIsDraft(false)} // Set isDraft to false for submitting
        >
          {issLoading ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </form>
            </div>
          </div>
        </div>

    
    </>
  );
};

export default Jobpostmodal;
