import React,{useState} from "react";
import { ToastContainer} from 'react-toastify';

import {Link, Outlet } from "react-router-dom";

import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Jobpostmodal from "./components/Jobpostmodal";
import Footer from "../components/Footer";

function Layout() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    // Clear job data
  };
  const openModal = () => {
    setIsModalOpen(true); // Close the modal
    // Clear job data
  };
  return (
    <>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <Navbar />

        <Sidebar />

        {/* Main content */}
        <div className="sm:p-4  py-10 lg:ml-64">
          <div className="sm:p-4 px-1 py-2 border-2 border-gray-200 border-dashed rounded-lg  mt-14">
          <ToastContainer  />
            <Outlet />
            <Footer/>
          </div>
        </div>

        {/* Bottom navbar for mobile */}
        <nav className="fixed bottom-0 left-0 z-50 w-full h-24 bg-white border-t border-gray-200 :bg-gray-700 :border-gray-600 sm:hidden">
          <div className="flex items-center justify-center  bg-gray-100">

         
          
          {isModalOpen&&(


<Jobpostmodal closeModal={closeModal}/> 
)}
             
            
             <button 
        onClick={openModal}
        className="px-4 sm:px-4 sm:py-2 py-1 lg:text-sm text-[14px] sm:font-bold font-semibold mb-2 text-gray-100 transition-colors duration-300 transform bg-[#0f3057] rounded-2xl cursor-pointer hover:bg-gray-500"
      >
        
        Post job
      </button>
          </div>
          <div className="grid h-fit mt-1 max-w-xl grid-cols-4 mx-auto">
            <Link to='main' >
      
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 "
            >
              <svg
                className="w-5 h-5 mb-1 text-gray-500  group-hover:text-blue-600 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              <span className="text-sm text-gray-500 group-hover:text-blue-600 ">
                Home
              </span>
            </button>
            </Link>
            <Link to='candidate'>
         
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 "
            >
              <svg className="w-5 h-5 mb-1 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
                    </svg>
              <span className="text-sm text-gray-500  group-hover:text-blue-600 ">
               Teachers
              </span>
            </button>
            </Link>
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-6 hover:bg-gray-50 "
            >
              <svg
                className="w-5 h-5 mb-1 text-gray-500  group-hover:text-blue-600 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 12.25V1m0 11.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M4 19v-2.25m6-13.5V1m0 2.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M10 19V7.75m6 4.5V1m0 11.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM16 19v-2"
                />
              </svg>
              <span className="text-sm text-gray-500 :text-gray-400 group-hover:text-blue-600 :group-hover:text-blue-500">
                Applicants
              </span>
            </button>
            <Link to='profile'>
         
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 :hover:bg-gray-800 group"
            >
              <svg
                className="w-5 h-5 mb-1 text-gray-500 :text-gray-400 group-hover:text-blue-600 :group-hover:text-blue-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
              </svg>
              <span className="text-sm text-gray-500 :text-gray-400 group-hover:text-blue-600 :group-hover:text-blue-500">
                Profile
              </span>
            </button>
            </Link>
         
          </div>
        </nav>
      </div>
    </>
  );
}

export default Layout;
