import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/last.png'
import { useAuth } from '../Utils/Context';

function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isactive, setactive] = useState(() => localStorage.getItem("activeTab") || "dash");

  useEffect(() => {
    // Save the current state to localStorage whenever it changes
    localStorage.setItem("activeTab", isactive);
  }, [isactive]);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
const {logout} = useAuth()

const loginingout = ()=>{
logout();
//  navigate('/')
  
}
  return (
    <>
      
      <div className="flex pl-[300px] md:pl-6 fixed p-2 mt-3 ms-3  text-sm z-50  lg:hidden">
            <button
               onClick={toggleSidebar}
              className="inline-flex items-center justify-center p-2 rounded-md text-white   "
            >
              <span className="sr-only">Open main menu</span>
              {isSidebarOpen ? (
                <svg className="block h-6 md:ml-[170px]  w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="blockh-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>
          </div>

      <aside
        id="sidebar-multi-level-sidebar"
        className={`fixed top-0 left-0  z-40 w-64 h-screen transition-transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0`}
        aria-label="Sidebar"
      >
        
        <div className="h-full px-0 py-[0px] shadow-2xl overflow-y-auto bg-[#0f3057]  ">
        <img
            className="    " 
            src={logo}
            alt="Logo"
            loading="lazy"
          />
          <ul className="space-y-2 px-4 border-t-2 -mt-4 font-medium">
            <li>
              <Link
              onClick={()=>{setactive("dash");setIsSidebarOpen(false)}}
                to="/dashboard/main"
                className={`${isactive === "dash"?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}
              >
                <svg className="w-5 h-5 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white" aria-hidden="true" fill="currentColor" viewBox="0 0 22 21">
                  <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                  <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                </svg>
                <span className="ms-3">Dashboard</span>
              </Link>
            </li>
           
             <li>
                <Link  onClick={()=>{setactive("job");setIsSidebarOpen(false)}}  to="/dashboard/jobposting"  className={`${isactive === "job" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                    <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Job Posting</span>
                  {/* <span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full :bg-gray-700 :text-gray-300">Pro</span> */}
                </Link>
              </li>
              <li>
                <Link onClick={()=>{setactive("save");setIsSidebarOpen(false)}}  to="/dashboard/saved"  className={`${isactive === "save"?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                <svg className="w-5 h-5 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white" fill='currentcolor' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"/></svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Save Drafts</span>
                 
                </Link>
              </li>
              <li>
                <Link onClick={()=>{setactive("charts");setIsSidebarOpen(false)}}  to="/dashboard/ComingSoon" className={`${isactive === "charts" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                <svg className="w-5 h-5 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white" fill='currentcolor' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64L0 400c0 44.2 35.8 80 80 80l400 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 416c-8.8 0-16-7.2-16-16L64 64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"/></svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Analytics</span>
                </Link>
              </li>
             
              <li>
                <Link onClick={()=>{setactive("candi");setIsSidebarOpen(false)}} to="/dashboard/candidate"  className={`${isactive === "candi" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                    <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Candidates</span>
                 </Link>
              </li>
              <li>
                <Link to='/dashboard/Subcription'onClick={()=>{setactive("Subscription");setIsSidebarOpen(false)}}  className={`${isactive === "Subscription" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Subscription</span>
                 </Link>
              </li>
              <li>
                <Link onClick={()=>{setactive("profile");setIsSidebarOpen(false)}} to="profile"  className={`${isactive === "profile" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                <svg className="w-5 h-5 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white" fill='currentcolor' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z"/></svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Profile</span>
                 </Link>
              </li>
              <li>
                <Link onClick={()=>{setactive("school");setIsSidebarOpen(false)}} to="school"  className={`${isactive==="school" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                <svg className="w-5 h-5 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white" fill='currentcolor' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M337.8 5.4C327-1.8 313-1.8 302.2 5.4L166.3 96 48 96C21.5 96 0 117.5 0 144L0 464c0 26.5 21.5 48 48 48l208 0 0-96c0-35.3 28.7-64 64-64s64 28.7 64 64l0 96 208 0c26.5 0 48-21.5 48-48l0-320c0-26.5-21.5-48-48-48L473.7 96 337.8 5.4zM96 192l32 0c8.8 0 16 7.2 16 16l0 64c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-64c0-8.8 7.2-16 16-16zm400 16c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 64c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-64zM96 320l32 0c8.8 0 16 7.2 16 16l0 64c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-64c0-8.8 7.2-16 16-16zm400 16c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 64c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-64zM232 176a88 88 0 1 1 176 0 88 88 0 1 1 -176 0zm88-48c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-16 0 0-16c0-8.8-7.2-16-16-16z"/></svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Schools</span>
                 </Link>
              </li>
          
              <li>
                <Link onClick={()=>{setactive("help");setIsSidebarOpen(false)}} to="contact"  className={`${isactive === "help" ?'bg-[#11a9b5]  hidden sm:flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'sm:flex hidden items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Help Center</span>
                 </Link>
              </li>
              <li onClick={  loginingout}>
                <Link onClick={()=>setactive("Log")} href="#"  className={`${isactive === "Log" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Log Out</span>
                 </Link>
              </li>
          </ul>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
