import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { customStyles, customClassNames } from "../components/Options";
import Select from "react-select";
import VirtualizedMenuList from "../../Utils/VirtualizedMenuList";
import { Location } from "../components/Options";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../Utils/Context";
import Loading from "../../Utils/Loading";
function School() {
  const [activeTab, setActiveTab] = useState("tab1");
  const [loading, setloading] = useState(false);
  const [school, setschool] = useState("");

  const { api } = useAuth();
  // Replace with your actual API URL

  const fetchSchoolData = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get("/api/view_school", {
      headers: {
        Authorization: `Bearer ${token}`, // Add Bearer token to the request
      },
    });
  
    return response.data;     // Assuming 'Success' contains the school data
  
  };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["schoolData"],
    queryFn: () => fetchSchoolData(),
  });

  useEffect(() => {
    if (data) {
      // Use reset to populate the form with fetched data
      reset({
        schoolname: data.school_name || "",
        Prinicipal: data.principal_name || "",
        schoolemail: data.school_email || "",
        schoolcontact: data.school_mobile || "",
        affiliationnumber: data.affilifation_no || "",
        UDISEno: data.udise_no || "",
        schooldescription: data.school_description || "",
        Schooladdress: data.school_address || "",
      });
    }
  }, [data, reset]);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSelectChange = (option, name) => {
    setValue(name, option ? option.value : null);
  };
  const onSubmit = async (data) => {
    setloading(true);
    try {
      const token = localStorage.getItem("token"); // get bearer token from localStorage
      // console.log("Form data:", data);

      const response = await api.post("/api/save_school", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data.Success) {
        toast.success(response.data.Success);
        // console.log("Response:", response.data);
      }
      // console.log("Response:", response.data);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error:", error);
    } finally {
      setloading(false);
    }
  };

  const { options } = Location();
  if (isLoading) {
    return <div><Loading/></div>;
  }

  if (isError) {
    return <div>Error fetching school data</div>;
  }
  return (
    <div className="p-5">
      {/* Tab Navigation */}
      <div className="flex border-b mb-12 gap-4">
        <button
          className={`p-2 ${
            activeTab === "tab1"
              ? "border-b-2 border-[#11a9b5] text-lg text-[#11a9b5]"
              : "font-semibold text-lg text-gray-d00"
          }`}
          onClick={() => handleTabClick("tab1")}
        >
          School List
        </button>
        <button
          className={`p-2 ${
            activeTab === "tab2"
              ? "border-b-2 border-[#11a9b5] text-lg text-[#11a9b5]"
              : "font-semibold text-lg text-gray-d00"
          }`}
          onClick={() => handleTabClick("tab2")}
        >
          Add School
        </button>
      </div>

      {/* Conditional Form Fields Rendering */}
      <div>
        {activeTab === "tab2" && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              {/* School Name */}
              <div>
                <label
                  htmlFor="schoolname"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  School name
                </label>
                <input
                  type="text"
                  id="schoolname"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  placeholder="School"
                  {...register("schoolname", {
                    required: "School name is required",
                  })}
                />
                {errors.schoolname && (
                  <p className="text-red-600 text-sm">
                    {errors.schoolname.message}
                  </p>
                )}
              </div>

              {/* Principal Name */}
              <div>
                <label
                  htmlFor="Prinicipal"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Principal name
                </label>
                <input
                  type="text"
                  id="Prinicipal"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  placeholder="Principal"
                  {...register("Prinicipal", {
                    required: "Principal name is required",
                  })}
                />
                {errors.Prinicipal && (
                  <p className="text-red-600 text-sm">
                    {errors.Prinicipal.message}
                  </p>
                )}
              </div>

              {/* School Number */}
              <div>
                <label
                  htmlFor="schoolcontact"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  School number
                </label>
                <input
                  type="tel"
                  id="schoolcontact"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  placeholder="123-45-678"
                  {...register("schoolcontact", {
                    required: "School number is required",
                    length: 10,
                    pattern: {
                      value: /^[6-9]\d{9}$/, // Ensures it starts with 6-9 and is 10 digits
                      message:
                        "Invalid Indian phone number (must be 10 digits)",
                    },
                  })}
                />
                {errors.schoolcontact && (
                  <p className="text-red-600 text-sm">
                    {errors.schoolcontact.message}
                  </p>
                )}
              </div>

              {/* Affiliation Number */}
              <div>
                <label
                  htmlFor="affiliationnumber"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Affiliation no.
                </label>
                <input
                  type="number"
                  id="affiliationnumber"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  placeholder="Affiliation"
                  {...register("affiliationnumber", {
                    required: "Affiliation number is required",
                  })}
                />
                {errors.affiliationnumber && (
                  <p className="text-red-600 text-sm">
                    {errors.affiliationnumber.message}
                  </p>
                )}
              </div>

              {/* UDISE Number */}
              <div>
                <label
                  htmlFor="udise"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  UDISE no.
                </label>
                <input
                  type="number"
                  id="UDISEno"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  placeholder="UDISE"
                  {...register("UDISEno", {
                    required: "UDISE number is required",
                  })}
                />
                {errors.UDISEno && (
                  <p className="text-red-600 text-sm">
                    {errors.UDISEno.message}
                  </p>
                )}
              </div>

              {/* School Email */}
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  School Email
                </label>
                <input
                  type="email"
                  id="schoolemail"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  placeholder="school@example.com"
                  {...register("schoolemail", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      message: "Invalid email format",
                    },
                  })}
                />
                {errors.schoolemail && (
                  <p className="text-red-600 text-sm">
                    {errors.schoolemail.message}
                  </p>
                )}
              </div>
            </div>

            {/* School Description */}
            <div className="mb-4">
              <label
                htmlFor="schooldescription"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                School Description
              </label>
              <textarea
                id="schooldescription"
                rows="4"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                placeholder="Write school description here"
                {...register("schooldescription" , {
                  required: "School description is required",
                })}
              ></textarea>
            </div>

            {/* Address */}
            <div>
              <label
                htmlFor="Schooladdress"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                School Address
              </label>
              <input
                type="text"
                id="Schooladdress"
                className="mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                placeholder="Address"
                {...register("Schooladdress", {
                  required: "School address is required",
                })}
              />
              {errors.Schooladdress && (
                <p className="text-red-600 text-sm">
                  {errors.Schooladdress.message}
                </p>
              )}

              {/* State Select */}
              <div>
                <label
                  htmlFor="state"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  State & City
                </label>
                <Select
                  onChange={(option) => handleSelectChange(option, "city")}
                  // Add necessary props here for react-select
                  placeholder="Search Skills"
                  isClearable
                  required = "School name is required"
                  styles={customStyles}
                  className="w-full"
                  classNamePrefix="react-select"
                  classNames={customClassNames}
                  options={options}
                  components={{ MenuList: VirtualizedMenuList }}
                />

                {errors.city && (
                  <p className="text-red-600 text-sm">{errors.city.message}</p>
                )}
              </div>
            </div>

            {/* Submit and Cancel Buttons */}
            <div className="flex justify-end gap-4">
              <button
                type="button"
                className="text-white bg-gray-500 hover:bg-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="text-white bg-[#0f3057] hover:bg-[#87c5cb] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        )}

        {activeTab === "tab1" && (
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 :text-gray-400">
              <thead class="text-xs text-[#11a9b5] uppercase bg-gray-50 :bg-gray-700 :text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    School Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Principal Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Email
                  </th>

                  <th scope="col" class="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
            
                <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-[#d9f1f3] :hover:bg-gray-600">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white"
                  >
                   {data.school_name}
                  </th>
                  <td class="px-6 py-4">{data.principal_name}</td>
                  <td class="px-6 py-4">{data.school_email}</td>

                  <td class="flex items-center px-6 py-4">
                    <button
                      onClick={() => handleTabClick("tab2")}
                      class="font-medium bg-[#0f3057] px-2 py-1 rounded-md text-white hover:underline"
                    >
                     {data.school_name?"Edit":"Add Schhol"} 
                    </button>
                    {/* <button
                      href="#"
                      class="font-medium bg-red-600 px-2 py-1 rounded-md text-white hover:underline ms-3"
                    >
                      Remove
                    </button> */}
                  </td>
                </tr>
               
                
              
               </tbody>
            </table>
          {!data.school_name && (<p className="text-center text-xl py-2 mt-2">Go to Add School to see School list</p>)}
          </div>
        )}
      </div>
     
    </div>
  );
}

export default School;
