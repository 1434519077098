import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";

import { useNavigate } from "react-router-dom";
import VirtualizedMenuList from "../Utils/VirtualizedMenuList";
import Header from "../Utils/Header";
import SkeletonCard from "../dashboard/components/Skeleton";
import { Designation, Location } from "../dashboard/components/Options";
import {  useLocation } from "react-router-dom";
import { set } from "react-hook-form";

const Alljobspage = () => {
  const [jobs, setJobs] = useState([]);
  const [location, setlocation] = useState(null);
  const [title, settitle] = useState(null);
  const location1 = useLocation();
  const [filtered, setfiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [jobsPerPage] = useState(8); 
  const [loading, setIsLoading] = useState(false);
  const [filterstate, setfilterstate] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const navigate = useNavigate();
  const [salary, setSalary] = useState(80000);

  const {options ,isLoading} = Location()
  const {options3 } = Designation()
  const baseApiUrl = process.env.REACT_APP_BASE_API_URL;


  // Fetch all jobs from the API
  useEffect(() => {
    const fetchAllJobs = async () => {
      setIsLoading(true)
      try {

        const response = await axios.get(
          `${baseApiUrl}/api/jobs_list`
        );
        setIsLoading(false);
        setJobs(response.data);
        setfiltered(response.data) // Set all jobs
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching jobs:", error);
      }
    };
    fetchAllJobs();
 
  }, []);
   // Create options for the select component
   useEffect(() => {
    const params = new URLSearchParams(location1.search);
  
    const locationParam = params.get("location");
    const titleParam = params.get("title");

    if (locationParam) {
      const locationOption = options?.find((opt) => opt.value === locationParam);
      setlocation(locationOption);
    }
  
    if (titleParam) {
      const titleOption = options3.find((opt) => opt.value === titleParam);
      settitle(titleOption);
    }
  }, [location1.search, options, options3]);
  
  useEffect(() => {
    const params = new URLSearchParams();
    setfilterstate(true);
    if (location) params.set("location", location.value);
    if (title) params.set("title", title.value);
    // Update the URL query parameters
    navigate({ search: params.toString() }, { replace: true });
   handlefilter();
  }, [location, title, jobs]);
  
  const handlefilter = () => {
    let filtered = jobs;
    if (location) {
      filtered = filtered.filter((job) => job.location === location.value);
    }
  
    if (title) {
      filtered = filtered.filter((job) => job.designation === title.value);
    }
  
  
    setNoResults(filtered.length === 0);
    setfiltered(filtered);
  };
  
  const handleApplyFilter = () => {
    // const params = new URLSearchParams();
    // setfilterstate(true);
    // if (location) params.set("location", location.value);
    // if (title) params.set("title", title.value);
    // // Update the URL query parameters
    // navigate({ search: params.toString() }, { replace: true });
    handlefilter();
  };
  

  const resetfilter = () => {
    setlocation(null);
    setfilterstate(false)
    settitle("");
    setNoResults(false);
    setSalary(80000)
    setfiltered(jobs);
    navigate({ search: "" }, { replace: true });
  };
  const handleCardClick = (jobId) => {
    navigate(`/job/${jobId}`);
  };
  // const maxLength = 10;
  // const truncatedDescription = desc ? desc.substring(0, 150) : "";
  // const truncatedDescriptionmob = desc ? desc.substring(0, 50) : "";
  const num = 10000;

   // Calculate total pages
   const totalPages = Math.ceil(filtered.length / jobsPerPage);

   // Get the jobs for the current page
   const indexOfLastJob = currentPage * jobsPerPage;
   const indexOfFirstJob = indexOfLastJob - jobsPerPage;
   const currentJobs = filtered.slice(indexOfFirstJob, indexOfLastJob);
 
   // Handle pagination button clicks
   const handleNextPage = () => {
     if (currentPage < totalPages) {
       setCurrentPage(currentPage + 1);
       scrollToTop();
     }
   };
 
   const handlePrevPage = () => {
     if (currentPage > 1) {
       setCurrentPage(currentPage - 1);
       scrollToTop();
     }
   };
 
   const handlePageClick = (pageNumber) => {
     setCurrentPage(pageNumber);
     scrollToTop();
     
   };
    
   const getPageNumbers = (currentPage, totalPages) => {
    let pageNumbers = [];
  
    if (totalPages <= 3) {
      // If total pages are 3 or less, show all pages
      pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else if (currentPage === 1) {
      // If on the first page, show 1, 2, 3, and the last page
      pageNumbers = [1, 2, 3, totalPages];
    } else if (currentPage === 2) {
      // If on the second page, show 1, 2, 3, and the last page
      pageNumbers = [1, 2, 3, totalPages];
    } else if (currentPage <= totalPages - 3) {
      // If in the middle, show previous, current, next, and last page
      pageNumbers = [currentPage - 1, currentPage, currentPage + 1, totalPages];
    } else {
      // If near the end, show last three pages
      pageNumbers = [totalPages - 2, totalPages - 1, totalPages];
    }
  
    return pageNumbers;
  };
  
  const scrollToTop = () => {
   
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // smooth scrolling
    });
  };

  if(loading) return <div className="sm:px-[150px]"><SkeletonCard/> </div>

  return (
    <>
      <Header title={"All Latest jobs"} highlight={"Postings"} subtitile={"Check out the latest job vacancies"}/>
     
      <div className="container px-5 sm:py-18 py-0  mb-14 mx-auto">
        <div class="grid  lg:grid-cols-7 grid-cols-1 w-full lg:gap-20 gap-4  lg:place-content-center">
          
          <div class="    lg:w-[80%]  lg:col-span-3 rounded-lg sm:p-8 p-0 md:ml-auto mt-4 sm:mt-10 md:mt-0">
            <div className="bg-gray-100   px-6 py-4 h-[400px]">
            <h2 class="text-[#0f3057] text-lg font-medium title-font mb-5">
              {" "}
              Search Jobs
            </h2>
            
            <div class="relative mb-4">
              <label for="location" class="leading-7 text-sm text-gray-800">
                Job titile
              </label>

              <Select
                id="search-select"
                options={options3} // Use dynamic options
              isLoading={loading}
              onChange={(selectedOption) => settitle(selectedOption)} // Set location based on select
              value={title}  // Set the current selected value}
                placeholder="Search and select..."
                components={{ MenuList: VirtualizedMenuList }}
              />
            </div>
            <div class="relative mb-4">
              <label for="location" class="leading-7 text-sm text-gray-800">
                Location
              </label>

              <Select
                id="search-select"
                options={options} // Use dynamic options
              isLoading={isLoading}
              onChange={(selectedOption) => setlocation(selectedOption)} // Set location based on select
              value={location}  // Set the current selected value}
                placeholder="Search and select..."
                components={{ MenuList: VirtualizedMenuList }}
              />
            </div>
            
            <div className="mb-4">
            <label className="leading-7 text-sm text-gray-800">Minimum Salary: ₹{salary} (P.A)</label>
            <input
              type="range"
              className="w-full"
              min="80000"
              max="1200000"
              step="10000"
              value={salary}
              onChange={(e) => setSalary(Number(e.target.value))}
            />
              </div>
              <div className="flex justify-around">

              <button
              onClick={resetfilter}
              class="text-white  bg-[#0f3057] border-0 sm:py-2 sm:px-8 px-2  py-1  focus:outline-none text-md hover:bg-[#3a3d41] rounded sm:text-lg"
            >
              Reset filter
            </button>
            <button
              onClick={ handleApplyFilter }
              class="text-white  bg-[#0f3057] border-0 sm:py-2 sm:px-8 px-2 py-1  focus:outline-none text-md hover:bg-[#3a3d41]  rounded sm:text-lg"
            >
              Apply filter
            </button>
            
            </div>
            <p class="text-xs text-gray-500 mt-3">
              Filter job vacancies based on your requirement
            </p>
            </div>
          </div>

          <div className="lg:col-span-4   space-y-4">
          {noResults ? (
            <p className="text-red-500 text-lg">No jobs found matching the criteria</p>
          ) : (
             filtered.length > 0
              ? currentJobs.map((job) => (
                  <div
                    key={job.id}
                    onClick={() => handleCardClick(job.id)}
                    class="lg:max-w-2xl px-0 lg:col-span-2  lg:px-8 sm:mt-8 lg:py-4 py-1 bg-[#d9f1f3] rounded-lg shadow-md hover:scale-105 transition-transform "
                  >
                    

                    <div class="mt-2 px-4 sm:px-1">
                      <p
                        href="#"
                        class="lg:text-xl text-sm sm:font-bold font-semibold text-[#0f3057]  hover:text-gray-600  hover:underline"
                        tabindex="0"
                        role="link"
                      >
                        {job.name}
                      </p>
                      <p
                        href="#"
                        class="lg:text-lg text-xs  font-semibold text-[#0f3057]  hover:text-gray-600  hover:underline"
                        tabindex="0"
                        role="link"
                      >
                        {job.school_name}
                      </p>
                      <div className="flex flex-wrap">
                        <div class="flex items-center  mt-1 text-[#11a9b5] ">
                          <svg
                            aria-label="suitcase icon"
                            class="sm:w-6 sm:h-6 h-3 w-3   fill-current"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M14 11H10V13H14V11Z" />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7 5V4C7 2.89545 7.89539 2 9 2H15C16.1046 2 17 2.89545 17 4V5H20C21.6569 5 23 6.34314 23 8V18C23 19.6569 21.6569 21 20 21H4C2.34314 21 1 19.6569 1 18V8C1 6.34314 2.34314 5 4 5H7ZM9 4H15V5H9V4ZM4 7C3.44775 7 3 7.44769 3 8V14H21V8C21 7.44769 20.5522 7 20 7H4ZM3 18V16H21V18C21 18.5523 20.5522 19 20 19H4C3.44775 19 3 18.5523 3 18Z"
                            />
                          </svg>

                          <h1 class="sm:px-2 px-1  sm:text-sm text-[13px] text-gray-600">
                            Full time{" "}
                          </h1>
                        </div>
                        <div class="flex items-center mt-1 text-[#11a9b5] ">
                          <svg
                            aria-label="location pin icon"
                            class="sm:w-6 sm:h-6 h-3 w-3  fill-current"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.2721 10.2721C16.2721 12.4813 14.4813 14.2721 12.2721 14.2721C10.063 14.2721 8.27214 12.4813 8.27214 10.2721C8.27214 8.063 10.063 6.27214 12.2721 6.27214C14.4813 6.27214 16.2721 8.063 16.2721 10.2721ZM14.2721 10.2721C14.2721 11.3767 13.3767 12.2721 12.2721 12.2721C11.1676 12.2721 10.2721 11.3767 10.2721 10.2721C10.2721 9.16757 11.1676 8.27214 12.2721 8.27214C13.3767 8.27214 14.2721 9.16757 14.2721 10.2721Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.79417 16.5183C2.19424 13.0909 2.05438 7.3941 5.48178 3.79418C8.90918 0.194258 14.6059 0.0543983 18.2059 3.48179C21.8058 6.90919 21.9457 12.606 18.5183 16.2059L12.3124 22.7241L5.79417 16.5183ZM17.0698 14.8268L12.243 19.8965L7.17324 15.0698C4.3733 12.404 4.26452 7.9732 6.93028 5.17326C9.59603 2.37332 14.0268 2.26454 16.8268 4.93029C19.6267 7.59604 19.7355 12.0269 17.0698 14.8268Z"
                            />
                          </svg>

                          <h1 class="sm:px-2 px-1  sm:text-sm text-[13px] text-gray-600">
                            {job.state_name} , {job.city_name}{" "}
                          </h1>
                        </div>
                      </div>
                      <p class="mt-2 text-gray-600 truncate-multi-line  text-justify text-[13px] sm:text-sm px-1">
                        {job.job_description }
                      </p>
                      {/* <p class="mt-2 text-gray-600  md:hidden text-[13px] px-1 ">{truncatedDescriptionmob}  </p> */}
                    </div>

                    <div class="flex items-center px-2 border-t-2  border-[#0f3057]  sm:px-1 justify-between mt-4">
                      <div class="flex items-center justify-between   text-[#11a9b5] ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          class="sm:w-6 sm:h-6 h-3 w-3   fill-current"
                        >
                          {" "}
                          <path
                            fill="#11a9b5"
                            d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-224c0-35.3-28.7-64-64-64L80 128c-8.8 0-16-7.2-16-16s7.2-16 16-16l368 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L64 32zM416 272a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
                          />
                        </svg>

                        <h1 class="px-2 sm:text-sm text-[13px] text-gray-600">
                          {" "}
                          ₹ {parseInt(job.salarymax)*12} (P. A)
                        </h1>

                      </div>
                      <div class="flex items-center mt-4">
                        <p
                          class="px-4 sm:px-6 sm:py-2 py-1 lg:text-sm text-[13px] sm:font-bold font-semibold mb-2 text-gray-100 transition-colors duration-300 transform bg-[#0f3057] rounded cursor-pointer hover:bg-gray-500"
                          tabindex="0"
                          role="button"
                        >
                          View Job
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              : jobs.map((job) => (
                  <div
                    key={job.id}
                    onClick={() => handleCardClick(job.id)}
                    class="lg:max-w-2xl px-0 lg:col-span-2  lg:px-8 lg:py-4 py-1 bg-[#d9f1f3] rounded-lg shadow-md hover:scale-105 transition-transform "
                  >
                    <div class="flex items-center px-2  justify-between">
                      <span class="sm:text-sm text-[13px]  font-light text-gray-600 ">
                        {job.max_created_at}
                      </span>
                    </div>

                    <div class="mt-2 px-4 sm:px-1">
                      <p
                        href="#"
                        class="lg:text-xl text-xs sm:font-bold font-semibold text-[#0f3057]  hover:text-gray-600  hover:underline"
                        tabindex="0"
                        role="link"
                      >
                        {job.name}
                      </p>
                      <p
                        href="#"
                        class="lg:text-lg text-xs  font-semibold text-[#0f3057]  hover:text-gray-600  hover:underline"
                        tabindex="0"
                        role="link"
                      >
                        {job.school_name}
                      </p>
                      <div className="flex flex-wrap">
                        <div class="flex items-center  mt-1 text-[#11a9b5] ">
                          <svg
                            aria-label="suitcase icon"
                            class="sm:w-6 sm:h-6 h-3 w-3   fill-current"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M14 11H10V13H14V11Z" />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7 5V4C7 2.89545 7.89539 2 9 2H15C16.1046 2 17 2.89545 17 4V5H20C21.6569 5 23 6.34314 23 8V18C23 19.6569 21.6569 21 20 21H4C2.34314 21 1 19.6569 1 18V8C1 6.34314 2.34314 5 4 5H7ZM9 4H15V5H9V4ZM4 7C3.44775 7 3 7.44769 3 8V14H21V8C21 7.44769 20.5522 7 20 7H4ZM3 18V16H21V18C21 18.5523 20.5522 19 20 19H4C3.44775 19 3 18.5523 3 18Z"
                            />
                          </svg>

                          <h1 class="sm:px-2 px-1  sm:text-sm text-[13px] text-gray-600">
                            Full time{" "}
                          </h1>
                        </div>
                        <div class="flex items-center mt-1 text-[#11a9b5] ">
                          <svg
                            aria-label="location pin icon"
                            class="sm:w-6 sm:h-6 h-3 w-3  fill-current"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.2721 10.2721C16.2721 12.4813 14.4813 14.2721 12.2721 14.2721C10.063 14.2721 8.27214 12.4813 8.27214 10.2721C8.27214 8.063 10.063 6.27214 12.2721 6.27214C14.4813 6.27214 16.2721 8.063 16.2721 10.2721ZM14.2721 10.2721C14.2721 11.3767 13.3767 12.2721 12.2721 12.2721C11.1676 12.2721 10.2721 11.3767 10.2721 10.2721C10.2721 9.16757 11.1676 8.27214 12.2721 8.27214C13.3767 8.27214 14.2721 9.16757 14.2721 10.2721Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.79417 16.5183C2.19424 13.0909 2.05438 7.3941 5.48178 3.79418C8.90918 0.194258 14.6059 0.0543983 18.2059 3.48179C21.8058 6.90919 21.9457 12.606 18.5183 16.2059L12.3124 22.7241L5.79417 16.5183ZM17.0698 14.8268L12.243 19.8965L7.17324 15.0698C4.3733 12.404 4.26452 7.9732 6.93028 5.17326C9.59603 2.37332 14.0268 2.26454 16.8268 4.93029C19.6267 7.59604 19.7355 12.0269 17.0698 14.8268Z"
                            />
                          </svg>

                          <h1 class="sm:px-2 px-1  sm:text-sm text-[13px] text-gray-600">
                            {job.state_name} , {job.city_name}{" "}
                          </h1>
                        </div>
                      </div>
                      <p class="mt-2 text-gray-600  text-[13px] sm:text-sm px-1">
                        {job.job_description?.substring(0, 300)}{"..."}
                      </p>
                      {/* <p class="mt-2 text-gray-600  md:hidden text-[13px] px-1 ">{truncatedDescriptionmob}  </p> */}
                    </div>

                    <div class="flex items-center px-4  sm:px-1 justify-between mt-4">
                      <div class="flex items-center  text-[#11a9b5] ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          class="sm:w-6 sm:h-6 h-3 w-3   fill-current"
                        >
                          {" "}
                          <path
                            fill="#11a9b5"
                            d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-224c0-35.3-28.7-64-64-64L80 128c-8.8 0-16-7.2-16-16s7.2-16 16-16l368 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L64 32zM416 272a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
                          />
                        </svg>

                        <h1 class="px-2 sm:text-sm text-[13px] text-gray-600">
                          {" "}
                          ₹ {job.salarymax} -{" "}
                          {parseInt(job.salarymax) + parseInt(num)}  (P. A)
                        </h1>
                      </div>

                      <div class="flex items-center">
                        <p
                          class="px-4 sm:px-6 sm:py-2 py-1 lg:text-sm text-[13px] sm:font-bold font-semibold mb-2 text-gray-100 transition-colors duration-300 transform bg-[#0f3057] rounded cursor-pointer hover:bg-gray-500"
                          tabindex="0"
                          role="button"
                        >
                          View Job
                        </p>
                      </div>
                    </div>
                  </div>
                )))}
                <div className="flex justify-evenly  lg:max-w-2xl">

                
                 <button onClick={handlePrevPage} class="text-white  bg-[#0f3057] border-0 sm:py-2 sm:px-8 px-2 py-1  focus:outline-none text-md hover:bg-[#3a3d41]  rounded sm:text-lg" disabled={currentPage === 1}>
          Prev
        </button>
        
        {/* Display page numbers */}
        {getPageNumbers(currentPage, totalPages).map((number, index) => (
          <button
            key={index}
            onClick={() => handlePageClick(number)}
            className={number === currentPage ? 'active text-white font-medium bg-black py-1 px-4 rounded-full' : ''}
          >
            {number === totalPages && index !== 0 ? ` ${number}` : number}
          </button>
        ))}

        <button onClick={handleNextPage} class="text-white  bg-[#0f3057] border-0 sm:py-2 sm:px-8 px-2 py-1  focus:outline-none text-md hover:bg-[#3a3d41]  rounded sm:text-lg" disabled={currentPage === totalPages}>
          Next
        </button>
        </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Alljobspage;
