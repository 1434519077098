import React, { useState } from 'react';
import { set, useForm } from 'react-hook-form';
import { useAuth } from '../Utils/Context';
const DeleteAccount = () => {
  const { register, handleSubmit, formState: { errors ,isSubmitting} } = useForm();
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [showotp, setshowotp] = useState(false);
  const {api} = useAuth();
  const onSubmit = async (data) => {
    try {
      const res = await api.post(`/api/account_delete_request?email=${data.email}`);
      console.log(res); 
      if (res.data.success) {
        setConfirmationMessage( res.data.message);
        console.log(res.data.message);
        res.data.message.includes('User') ? setshowotp(false) : setshowotp(true);;
      } 
    } catch (error) {
      console.log(error);
      setshowotp(false);

      setConfirmationMessage('An error occurred. Please try again later.');
    }
  
  };

  return (
    <div className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-md mt-10">
      <h2 className="text-2xl font-semibold text-center text-red-600 mb-6">Delete Account</h2>
      <p className="text-center text-gray-600 mb-8">To proceed, please confirm your email address associated with this account.</p>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <label htmlFor="email" className="block text-gray-700 font-medium mb-2">Email Address</label>
          <input
            type="email"
            id="email"
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Please enter a valid email address'
              }
            })}
            className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${
              errors.email ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-blue-500'
            }`}
            placeholder="you@example.com"
          />
          {errors.email && (
            <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
          )}
        </div>
       
{setshowotp &&(

  <button
  type="submit"
  disabled={isSubmitting}
  className="w-full bg-red-600  hover:bg-red-700 text-white font-semibold py-2 rounded-lg transition-colors"
  >
         {isSubmitting ? 'loading...' : 'Delete Account'}
        </button>
        )}
      </form>

      {confirmationMessage && (
        <div className="mt-6 p-4 bg-blue-50 border border-blue-200 text-blue-700 rounded-lg">
          <p>{confirmationMessage}</p>
        </div>
      )}
       {showotp && (
          <div className="mt-4">
            <label htmlFor="otp" className="block text-gray-700 font-medium mb-2">
              Enter the OTP sent to your email address
            </label>
            <input
              type="text"
              id="otp"
              {...register('otp', {
                required: 'OTP is required',
                pattern: {
                  value: /^[0-9]{6}$/,
                  message: 'Please enter a valid OTP'
                }
              })}
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${
                errors.otp ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-blue-500'
              }`}
              placeholder="123456"
            />
            {errors.otp && (
              <p className="text-red-500 text-sm mt-1">{errors.otp.message}</p>
            )}
             <button
             onClick={() => setConfirmationMessage('we have received your request, your account will be deleted in 15 days')}      
  className="w-full bg-green-400  mt-4 text-white font-semibold py-2 rounded-lg transition-colors"
  >
         Confirm OTP
        </button>
          </div>
          
        )}
    </div>
  );
};

export default DeleteAccount;
