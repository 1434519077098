// SubscriptionOverPage.jsx
import React from "react";

const SubscriptionOverPage = () => {
  return (
    <div className="h-full  py-2 bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg  shadow-lg max-w-lg w-full text-center">
        <h2 className="text-3xl font-bold text-red-500  mb-6">
             Oops!!
         </h2>
        <h2 className="text-3xl font-bold text-[#11a9b5]  mb-6">
          Your Subscription Has<span className="text-blue-950"> Expired </span> 
        </h2>
        <p className="text-lg text-gray-600 mb-6">
          We're sorry, but your   <span className="text-red-500"> subscription </span> has expired. To continue using the
          services, please renew your subscription.
        </p>
     
      </div>
    </div>
  );
};

export default SubscriptionOverPage;
