import React ,{useState} from 'react'
import Header from '../../Utils/Header';
import { useAuth } from "../../Utils/Context";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Buyplan({data}) {
    const {api} = useAuth()
    const [loading, setloading] = useState(false);
      const handlebuynow= async(id)=>{
        setloading(true)
        const token = localStorage.getItem("token");
        try {
          const response = await api.post(
            `/api/employer/Package_BuyEnquiry?pack_id=${id}`,
            {}, // Post body, if required
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if(response.data){
          toast.success(response.data.Success ,{position: "top-center",
            width:600,
          })
    
        }
        }catch (error) {
          console.error("Failed to fetch response:", error);
          throw new Error("Failed to fetch response");
        }finally{
          setloading(false)
        }
      }
  return (
    <>
    <Header title={"Subcription"} highlight={"Plan"} subtitile={"See our latest Subcription Plans"}/>
<div class="container px-5 py-10 mx-auto">
<div class="flex flex-col text-center w-full mb-10">
<h1 class="sm:text-3xl text-xl font-bold title-font mb-2 text-gray-900">Recruit Teachers From <span className="text-[#11a9b5]">India’s Largest </span>  Teachers’ Database
</h1>
<h1 class="sm:text-xl text-sm font-sans  mb-2 text-gray-800">MySchoolNaukri is a <span className="text-[#11a9b5]">national-level teachers’ database </span>    created
specifically for schools. Teachers from all over India register onto our
platform.
</h1>


</div>
<div class="flex flex-wrap justify-evenly -m-4">
{
    data.map((pack_plan)=>
        {
            return (
                <div key={pack_plan.id} class="p-3 xl:w-1/3 md:w-1/2 w-full">
                    <div class="h-full p-6 rounded-lg border-2  hover:scale-105 transition-all border-gray-300 flex flex-col relative overflow-hidden">
                        <h2 class="text-sm tracking-widest title-font mb-1 font-medium">{pack_plan.subscription_plan}</h2>
                        <h1 class="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">₹ {pack_plan.subscription_price}/-</h1>

                        <p class="flex items-center text-gray-600 mb-2">
                            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-[#11a9b5] text-white rounded-full flex-shrink-0">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>{pack_plan.total_job_post_no} Job Postings
                        </p>
                        <p class="flex items-center text-gray-600 mb-2">
                            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-[#11a9b5] text-white rounded-full flex-shrink-0">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>{pack_plan.total_cv_downloads} CV downloads
                        </p>

                        <p class="flex items-center text-gray-600 mb-2">
                            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-[#11a9b5] text-white rounded-full flex-shrink-0">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>Emails & Contact Info
                        </p>
                        <p class="flex items-center text-gray-600 mb-2">
                            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-[#11a9b5] text-white rounded-full flex-shrink-0">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>{pack_plan.data_access_validity} months Access to Database
                        </p>
                        <p class="flex items-center text-gray-600 mb-2">
                            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-[#11a9b5] text-white rounded-full flex-shrink-0">
                              
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span> {pack_plan.in_built_ats === 1 ? "In built ATS" : " No ATS"}
                        </p>
                        <p class="flex items-center text-gray-600 mb-6">
                            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-[#11a9b5] text-white rounded-full flex-shrink-0">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>{pack_plan.tech_support_status === 1 ? "Tech Support" : " No Tech Support"}
                        </p>
                        <button type='button' disabled={loading} onClick={()=>handlebuynow(pack_plan.id)} class="flex items-center mt-auto text-white bg-[#11a9b5] border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded">Buy Now
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </button>

                    </div>
                </div>
            );
        }
    )
}



</div>
</div>
    </>  )
}

export default Buyplan