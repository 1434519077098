import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useFormik } from "formik";
// import bg from "../assets/signteacher.png";
import bg2 from "../assets/teacherdesk.png";
import bg from "../assets/teacher.jpg";
import VirtualizedMenuList from "../Utils/VirtualizedMenuList";
import {
  ValidationSchemathree,
  validationSchemaone,
  validationSchemafour,
  validationexp,
} from "../Schemas/TeacherSchema";
import { Fetchdata } from "../Utils/Fetchdata";
import RecentJob from "./RecentJob";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const SingupTeacher = () => {
  const [step, setStep] = useState(1); // Current step
  const [resumeFile, setResumeFile] = useState(null);
  const [loading1, setloading1] = useState(false);
  const [isExperienced, setIsExperienced] = useState(false);
  const [employmentStatus, setEmploymentStatus] = useState("");
const navigate = useNavigate()
  const handleSelectChange = (event) => {
    formik.setFieldValue("employmentStatus", event.target.value);
    setEmploymentStatus(event.target.value);
  };
  const handleSubmit = async (values) => {
    setloading1(true)
    try {
      // Post form data to API
      const response = await axios.post(
        "https://info.myschoolnaukri.com/api/onboarding_thanku",
        values ,{
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      toast.success("Registration Successful, Verification email has been sent")
      setloading1(false)
      console.log("Success:", response.data.msg);
      navigate('/onboarding_thanku')
    } catch (error) {
      console.log(error.response.data.msg);
      toast.error("something went wrong , Please try again later");
      if (error.response && error.response.status === 409) {
        // Conflict error, display toast notification
        toast.error("Conflict occurred: " + error.response.data.message);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      contactNumber: "",
      email: "",
      gender: "",
      jobLocation: null,
      resume: null,
      password: "",
      confirmPassword: "",
      employmentStatus: "",
      jobProfileTitle: null,
      schoolName: "",
      locations: null,
      noticePeriod: "",
      totalTeachingExperience: "",
      totalAdministrativeExperience: "",
      educationtype: "",
      degree: null,
      fieldOfStudy: null,
      startyear: "",
      endyear: "",
      file: null,
      url: "",
    },
    validationSchema:
      step === 1
        ? validationSchemaone
        : step === 2
        ? validationexp
        : step === 3
        ? ValidationSchemathree
        : step === 4
        ? validationSchemafour
        : null,
    onSubmit: (values) => {
      if (step === 4) {
        const modifiedData = {
          ...values,
          employmentStatus: values.employmentStatus === "experienced" ? "1" : "0", // Convert to '1' or '0'
          jobLocation: values.jobLocation.value,
          resume:values.resume,
          locations: values.employmentStatus === "experienced" ? values.locations.value : null, // Set to null if not experienced
          jobProfileTitle: values.employmentStatus === "experienced" ? values.jobProfileTitle.value : null,
          degree: values.degree.value,
          fieldOfStudy: values.fieldOfStudy.value,
          gender: values.gender === "male" ? 0 : 1,
        };
        handleSubmit(modifiedData);
      } else {
        setStep(step + 1);
      }
    },
    validateOnMount: true,
  });

  const handleFileChange = (event) => {
    formik.setFieldValue('resume', event.currentTarget.files[0]);

  };
  

  //for prefered location..........
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [options3, setOptions3] = useState([]);
  const [loading, setIsLoading] = useState(false); // Store fetched options
  // Loading state

  // Fetch data from API
  useEffect(() => {
    const fetchOptions = async () => {
      if (step === 1) {
        setIsLoading(true);
        const data = await Fetchdata(
          "https://info.myschoolnaukri.com/api/location_list"
        );
        const optionsData = data.map((item) => ({
          value: item.id,
          label: item.state_name + " - " + item.city_name,
        }));
        setOptions(optionsData);
        setIsLoading(false);
      }
      if (step) {
        const data = await Fetchdata(
          "https://info.myschoolnaukri.com/api/designation_list"
        );
        const optionsData = data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setOptions2(optionsData);
      }
      if (step) {
        const data = await Fetchdata(
          "https://info.myschoolnaukri.com/api/courses_list"
        );
        const optionsData = data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setOptions3(optionsData);
      }
    };

    fetchOptions();
  }, [step]);

  return (
    <>
      <div class="flex justify-evenly  ">
        <ToastContainer/>
        <div className="max-w-4xl  sm:p-8 sm:mt-10  bg-white p-4 rounded-lg shadow-lg">
        <img
            class="object-cover sm:hidden  object-center rounded"
            alt="hero"
            src={bg}
          />
          <h2 className="text-xl text-center font-semibold text-[#0f3057] capatalize mb-6">
            Become a member of the{" "}
            <span className="text-[#11a9b5] font-semibold">
              {" "}
              India's Largest Teachers' Community{" "}
            </span>{" "}
            in just 4 steps.
          </h2>

          {/* Step Progress */}
          <div className="flex justify-between items-center space-x-4 mb-6">
            <span
              className={`border-2 px-2 py-0 ${
                step >= 1 ? "bg-[#10aab4] text-white" : "bg-gray-300"
              } rounded-full`}
            >
              1
            </span>
            <div
              className={`w-1/3 h-2 ${
                step >= 1 ? "bg-[#0f3057]" : "bg-gray-300"
              } rounded-lg`}
            ></div>
            <span
              className={`border-2 px-2 py-0 ${
                step >= 2 ? "bg-[#10aab4] text-white" : "bg-gray-300"
              } rounded-full`}
            >
              2
            </span>
            <div
              className={`w-1/3 h-2 ${
                step >= 2 ? "bg-[#0f3057]" : "bg-gray-300"
              } rounded-lg`}
            ></div>
            <span
              className={`border-2 px-2 py-0 ${
                step >= 3 ? "bg-[#10aab4] text-white" : "bg-gray-300"
              } rounded-full`}
            >
              3
            </span>
            <div
              className={`w-1/3 h-2 ${
                step >= 3 ? "bg-[#0f3057]" : "bg-gray-300"
              } rounded-lg`}
            ></div>
            <span
              className={`border-2 px-2 py-0 ${
                step >= 4 ? "bg-[#10aab4] text-white" : "bg-gray-300"
              } rounded-full`}
            >
              4
            </span>
          </div>

          {/* Form Content */}
         
          <form onSubmit={formik.handleSubmit}>
            {step === 1 && (
              <div>
                <h3 className="text-lg text-gray-500   mb-4">
                  <i>
                    Your contact information will be shared with schools who are
                    recruiting.
                  </i>
                </h3>
                <div className="grid grid-cols-1 gap-6 mb-4 mt-8 md:mb-6 md:grid-cols-2">
                  {/* First Name */}
                  <div>
                    <label className="block mb-2 text-sm text-[#0f3057] font-semibold">
                      First Name <span className="text-red-600"> * </span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="John"
                      className={`block w-full px-5 py-3 mt-2 text-gray-700 bg-white border ${
                        formik.errors.firstName && formik.touched.firstName
                          ? "border-red-600"
                          : "border-gray-200"
                      } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <div className="text-red-600 text-sm mt-1">
                        {formik.errors.firstName}
                      </div>
                    )}
                  </div>

                  {/* Contact Number */}
                  <div>
                    <label className="block mb-2 text-sm text-[#0f3057] font-semibold">
                      Contact Number <span className="text-red-600"> * </span>
                    </label>
                    <div class="relative border-r-2">
        <div class="absolute inset-y-0 start-0 top-6  flex items-center ps-3.5 pointer-events-none">
            +91
        </div>
        </div>
                    <input
                      type="tele"
                      name="contactNumber"
                      placeholder="XXXXX-XXXXX"
                      className={`block w-full px-5 ps-12 py-3 mt-2 text-gray-700 bg-white border ${
                        formik.errors.contactNumber &&
                        formik.touched.contactNumber
                          ? "border-red-600"
                          : "border-gray-200"
                      } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contactNumber}
                    />
                    {formik.touched.contactNumber &&
                      formik.errors.contactNumber && (
                        <div className="text-red-600 text-sm mt-1">
                          {formik.errors.contactNumber}
                        </div>
                      )}
                  </div>

                  {/* Email */}
                  <div>
                    <label className="block mb-2 text-sm text-[#0f3057] font-semibold">
                      Email address <span className="text-red-600"> * </span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="johnsnow@example.com"
                      className={`block w-full px-5 py-3 mt-2 text-gray-700 bg-white border ${
                        formik.errors.email && formik.touched.email
                          ? "border-red-600"
                          : "border-gray-200"
                      } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="text-red-600 text-sm mt-1">
                        {formik.errors.email}
                      </div>
                    )}
                  </div>

                  {/* Gender */}
                  <div>
                    <label className="block mb-2 text-sm text-[#0f3057] font-semibold">
                      Gender <span className="text-red-600"> * </span>
                    </label>
                    <select
                      name="gender"
                      className={`block w-full px-5 py-3 mt-2 text-gray-700 bg-white border ${
                        formik.errors.gender && formik.touched.gender
                          ? "border-red-600"
                          : "border-gray-200"
                      } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.gender}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {formik.touched.gender && formik.errors.gender && (
                      <div className="text-red-600 text-sm mt-1">
                        {formik.errors.gender}
                      </div>
                    )}
                  </div>

                  {/* Job Location */}
                  <div>
                    <label className="block mb-2 text-sm text-[#0f3057] font-semibold">
                       Location{" "}
                      <span className="text-red-600"> * </span>
                    </label>
                    <Select
                      id="search-select"
                      options={options}
                      isLoading={loading}
                      onChange={(option) =>
                        formik.setFieldValue("jobLocation", option)
                      }
                      onBlur={formik.handleBlur}
                      value={formik.values.jobLocation}
                      isClearable
                      placeholder="Search and select..."
                      components={{ MenuList: VirtualizedMenuList }}
                    />
                    {formik.touched.jobLocation &&
                      formik.errors.jobLocation && (
                        <div className="text-red-600 text-sm mt-1">
                          {formik.errors.jobLocation}
                        </div>
                      )}
                  </div>

                  {/* Resume */}
                  <div>
                    <label className="block text-sm text-[#0f3057] font-semibold">
                      Resume <span className="text-red-600"> * </span>
                    </label>
                    <input
                      type="file"
                      name="resume"
                      onChange={handleFileChange}
                      onBlur={formik.handleBlur}
                      accept=".pdf,.jpg,.jpeg,.doc"
                      className={`block w-full px-3 py-2 mt-2 text-sm text-gray-600 bg-white border ${
                        formik.errors.resume && formik.touched.resume
                          ? "border-red-600"
                          : "border-gray-200"
                      } rounded-lg`}
                    />
                    {formik.touched.resume && formik.errors.resume && (
                      <div className="text-red-600 text-sm mt-1">
                        {formik.errors.resume}
                      </div>
                    )}
                  </div>
                 

                  {/* Password */}
                  <div>
                    <label className="block mb-2 text-sm text-[#0f3057] font-semibold">
                      Password <span className="text-red-600"> * </span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      placeholder="Enter your password"
                      className={`block w-full px-5 py-3 mt-2 text-gray-700 bg-white border ${
                        formik.errors.password && formik.touched.password
                          ? "border-red-600"
                          : "border-gray-200"
                      } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className="text-red-600 text-sm mt-1">
                        {formik.errors.password}
                      </div>
                    )}
                  </div>

                  {/* Confirm Password */}
                  <div>
                    <label className="block mb-2 text-sm text-[#0f3057] font-semibold">
                      Confirm Password <span className="text-red-600"> * </span>
                    </label>
                    <input
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm your password"
                      className={`block w-full px-5 py-3 mt-2 text-gray-700 bg-white border ${
                        formik.errors.confirmPassword &&
                        formik.touched.confirmPassword
                          ? "border-red-600"
                          : "border-gray-200"
                      } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                    />
                    {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword && (
                        <div className="text-red-600 text-sm mt-1">
                          {formik.errors.confirmPassword}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )}

            {step === 2 && (
              <div>
                <h3 className="text-md mb-4">
                  Enter your latest work experience details. If you're a
                  fresher, then select fresher.
                </h3>
                <div className="mb-12">
                  <label class="block mb-2 text-md text-[#0f3057] font-semibold  ">
                    Employment Status <span className="text-red-600"> * </span>
                  </label>
                  <select
                    name="employmentStatus"
                    value={employmentStatus}
                    onChange={handleSelectChange}
                    onBlur={formik.handleBlur}
                    className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg 0 focus:border-[#11a9b5]  focus:ring-[#11a9b5] focus:outline-none focus:ring focus:ring-opacity-40"
                  >
                    <option value="">Select Employment Status</option>
                    <option value="fresher">Fresher</option>
                    <option value="experienced">Experienced</option>
                  </select>

                  {formik.errors.employmentStatus &&
                    formik.touched.employmentStatus && (
                      <div className="text-red-600">
                        {formik.errors.employmentStatus}
                      </div>
                    )}
                </div>

                {employmentStatus === "experienced" && (
                  <div>
                    <h3 className="text-lg font-semibold mb-4">
                      Work Experience Details
                    </h3>
                    <div className="grid grid-cols-1 gap-6 mb-4 mt-8 md:mb-6 md:grid-cols-2">
                      <div>
                        <label class="block mb-2 text-sm text-[#0f3057] font-semibold :text-gray-200">
                          Job Profile Title{" "}
                          <span className="text-red-600">* </span>
                        </label>
                        <Select
                          name="jobProfileTitle"
                          options={options2}
                          onChange={(option) =>
                            formik.setFieldValue("jobProfileTitle", option)
                          }
                          onBlur={formik.handleBlur}
                          components={{ MenuList: VirtualizedMenuList }}
                          value={formik.values.jobProfileTitle}
                          placeholder="Select Job Profile"
                        />
                        {formik.errors.jobProfileTitle &&
                          formik.touched.jobProfileTitle && (
                            <div className="text-red-600">
                              {formik.errors.jobProfileTitle}
                            </div>
                          )}
                      </div>

                      <div>
                        <label class="block mb-2 text-sm text-[#0f3057] font-semibold :text-gray-200">
                          School Name <span className="text-red-600">* </span>
                        </label>
                        <input
                          type="text"
                          name="schoolName"
                          className={`block w-full px-5 py-3 mt-2 text-gray-700 bg-white border ${
                            formik.errors.firstName && formik.touched.firstName
                              ? "border-red-600"
                              : "border-gray-200"
                          } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.schoolName}
                        />
                        {formik.touched.schoolName &&
                          formik.errors.schoolName && (
                            <div className="text-red-600 text-sm mt-1">
                              {formik.errors.schoolName}
                            </div>
                          )}
                      </div>

                      <div>
                        <label class="block mb-2 text-sm text-[#0f3057] font-semibold :text-gray-200">
                          Locations <span className="text-red-600"> * </span>
                        </label>
                        <Select
                          id="search-select"
                          options={options}
                          isLoading={loading}
                          onChange={(option) =>
                            formik.setFieldValue("locations", option)
                          }
                          onBlur={formik.handleBlur}
                          value={formik.values.locations}
                          isClearable
                          placeholder="Select Job Locations"
                          components={{ MenuList: VirtualizedMenuList }}
                        />
                        {formik.errors.locations &&
                          formik.touched.locations && (
                            <div className="text-red-600">
                              {formik.errors.locations}
                            </div>
                          )}
                      </div>
                      <div>
                        <label class="block mb-2 text-sm text-[#0f3057] font-semibold :text-gray-200">
                          Notice Period <i>(In months)</i>{" "}
                          <span className="text-red-600"> * </span>
                        </label>
                        <input
                          type="number"
                          name="noticePeriod"
                          className={`block w-full px-5 py-3 mt-2 text-gray-700 bg-white border ${
                            formik.errors.noticePeriod &&
                            formik.touched.noticePeriod
                              ? "border-red-600"
                              : "border-gray-200"
                          } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.noticePeriod}
                        />
                        {formik.touched.noticePeriod &&
                          formik.errors.noticePeriod && (
                            <div className="text-red-600 text-sm mt-1">
                              {formik.errors.noticePeriod}
                            </div>
                          )}
                      </div>
                      <div>
                        <label class="block mb-2 text-sm text-[#0f3057] font-semibold :text-gray-200">
                          Total Teaching Experience <i>(In years)</i>{" "}
                          <span className="text-red-600"> * </span>
                        </label>
                        <input
                          type="number"
                          name="totalTeachingExperience"
                          className={`block w-full px-5 py-3 mt-2 text-gray-700 bg-white border ${
                            formik.errors.totalTeachingExperience &&
                            formik.touched.totalTeachingExperience
                              ? "border-red-600"
                              : "border-gray-200"
                          } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.totalTeachingExperience}
                        />
                        {formik.touched.totalTeachingExperience &&
                          formik.errors.totalTeachingExperience && (
                            <div className="text-red-600 text-sm mt-1">
                              {formik.errors.totalTeachingExperience}
                            </div>
                          )}
                      </div>
                      <div>
                        <label class="block mb-2 text-sm text-[#0f3057] font-semibold ">
                          Total Administrative Experience <i>(In years)</i>{" "}
                          <span className="text-red-600"> * </span>
                        </label>

                        <input
                          type="number"
                          name="totalAdministrativeExperience"
                          className={`block w-full px-5 py-3 mt-2 text-gray-700 bg-white border ${
                            formik.errors.totalAdministrativeExperience &&
                            formik.touched.totalAdministrativeExperience
                              ? "border-red-600"
                              : "border-gray-200"
                          } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.totalAdministrativeExperience}
                        />
                        {formik.touched.totalAdministrativeExperience &&
                          formik.errors.totalAdministrativeExperience && (
                            <div className="text-red-600 text-sm mt-1">
                              {formik.errors.totalAdministrativeExperience}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {step === 3 && (
              <div>
                <h3 className="text-lg font-semibold text-center mb-4">
                  Enter your highest Qualification
                </h3>
                <div className="grid grid-cols-1 gap-6 mb-4 mt-8 md:mb-6 md:grid-cols-2">
                  <div>
                    <label class="block mb-2 text-md text-[#0f3057] font-semibold ">
                      Education Type <span className="text-red-600"> * </span>
                    </label>
                    <select
                      name="educationtype"
                      className={`block w-full px-5 py-3 mt-2 text-gray-700 bg-white border ${
                        formik.errors.educationtype &&
                        formik.touched.educationtype
                          ? "border-red-600"
                          : "border-gray-200"
                      } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.educationtype}
                    >
                      <option value="">Select Graduation</option>
                      <option value="graduate">Graduate</option>
                      <option value="postgraduate">Post Graduate</option>
                    </select>
                    {formik.touched.educationtype &&
                      formik.errors.educationtype && (
                        <div className="text-red-600 text-sm mt-1">
                          {formik.errors.educationtype}
                        </div>
                      )}
                  </div>
                 <div>
                    <label class="block mb-2 text-md text-[#0f3057] font-semibold ">
                      Degree <span className="text-red-600"> * </span>
                    </label>
                    <Select
                      id="search-select"
                      options={options3}
                      onChange={(option) =>
                        formik.setFieldValue("degree", option)
                      }
                      onBlur={formik.handleBlur}
                      value={formik.values.degree}
                      isClearable
                      placeholder="Select degree"
                      components={{ MenuList: VirtualizedMenuList }}
                      className={`block w-full px-1 py-2 mt-2 text-gray-700 bg-white border ${
                        formik.errors.degree && formik.touched.degree
                          ? "border-red-600"
                          : "border-gray-200"
                      } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                    />
                    {formik.errors.degree && formik.touched.degree && (
                      <div className="text-red-600">{formik.errors.degree}</div>
                    )}
                  </div>
                  <div>
                    <label class="block mb-2 text-md text-[#0f3057] font-semibold ">
                      Field of Study <span className="text-red-600"> * </span>
                    </label>
                    <Select
                      id="search-select"
                      options={options3}
                      isLoading={loading}
                      onChange={(option) =>
                        formik.setFieldValue("fieldOfStudy", option)
                      }
                      onBlur={formik.handleBlur}
                      value={formik.values.fieldOfStudy}
                      isClearable
                      placeholder="Select fieldofstudy"
                      components={{ MenuList: VirtualizedMenuList }}
                      className={`block w-full px-1 py-2 mt-2 text-gray-700 bg-white border ${
                        formik.errors.fieldOfStudy &&
                        formik.touched.fieldOfStudy
                          ? "border-red-600"
                          : "border-gray-200"
                      } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                    />
                    {formik.errors.fieldOfStudy &&
                      formik.touched.fieldOfStudy && (
                        <div className="text-red-600">
                          {formik.errors.fieldOfStudy}
                        </div>
                      )}
                  </div>
                  <div>
                    <label class="block mb-2 text-sm text-[#0f3057] font-semibold ">
                      Start/Completion Year{" "}
                      <span className="text-red-600"> * </span>
                    </label>
                    <div className="flex w-full space-x-6 justify-center ">
                      <div>
                        <input
                          type="number"
                          name="startyear"
                          placeholder="start year"
                          className={`block w-full px-5 py-3 mt-2 text-gray-700 bg-white border ${
                            formik.errors.startyear && formik.touched.startyear
                              ? "border-red-600"
                              : "border-gray-200"
                          } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.startyear}
                        />
                        {formik.touched.startyear &&
                          formik.errors.startyear && (
                            <div className="text-red-600 text-sm mt-1">
                              {formik.errors.startyear}
                            </div>
                          )}
                      </div>
                      <div>
                        <input
                          type="number"
                          name="endyear"
                          placeholder="Completion year"
                          className={`block w-full px-5 py-3 mt-2 text-gray-700 bg-white border ${
                            formik.errors.endyear && formik.touched.endyear
                              ? "border-red-600"
                              : "border-gray-200"
                          } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.endyear}
                        />
                        {formik.touched.endyear && formik.errors.endyear && (
                          <div className="text-red-600 text-sm mt-1">
                            {formik.errors.endyear}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {step === 4 && (
              <div>
                <h3>Upload a Profile video or paste a URL</h3>
                <div className="flex flex-col sm:flex-row mt-10 mb-10 justify-between items-center">
                  <div>
                    <label
                      for="image"
                      class="block text-sm text-[#0f3057] font-semibold "
                    >
                      Upload a Profile video{" "}
                      <span className="text-red-600"> * </span>
                    </label>

                    <input
                      type="file"
                      name="file"
                      accept="video/*"
                      className={`block w-full px-5 py-3 mt-2 text-gray-700 bg-white border ${
                        formik.errors.file && formik.touched.file
                          ? "border-red-600"
                          : "border-gray-200"
                      } rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.file}
                    />
                    {formik.touched.file && formik.errors.file && (
                      <div className="text-red-600 text-sm mt-1">
                        {formik.errors.file}
                      </div>
                    )}
                  </div>
                  <i>OR</i>
                  <div>
                    <label
                      for="image"
                      class="block text-sm text-[#0f3057] font-semibold "
                    >
                      Upload a Url <span className="text-red-600"> * </span>
                    </label>

                    <input
                      type="url"
                      name="url"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.url}
                      className={`block w-full px-3 py-2 mt-2 text-sm text-gray-600 bg-white border ${
                        formik.errors.url && formik.touched.url
                          ? "border-red-600"
                          : "border-gray-200"
                      } rounded-lg`}
                    />
                    {formik.touched.url && formik.errors.url && (
                      <div className="text-red-600 text-sm mt-1">
                        {formik.errors.url}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div
              className={`flex justify-between  ${
                step === 1 ? "lg:mx-[730px]" : ""
              }`}
            >
              {step > 1 && (
                <button
                  type="button"
                  onClick={() => setStep(step - 1)}
                  className="ml-4 mt-4  px-6 py-3 bg-gray-300 hover:bg-gray-400 font-semibold rounded-lg"
                >
                  Previous
                </button>
              )}
            {loading1 && (

              <div className="fixed inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50">
      <div role="status">
    <svg aria-hidden="true" class="w-14 h-14 text-gray-200 animate-spin  fill-blue-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>
    </div> )}
              <button
                type="submit"
                // disabled={!formik.isValid || !formik.dirty}
                className={`mt-4 ml-4 px-6 py-3 rounded-lg text-white font-semibold  ${
                  formik.isValid && formik.dirty
                    ? "bg-[#0f3057] hover:bg-blue-100 hover:text-black"
                    : "bg-gray-400 cursor-not-allowed"
                } rounded-lg`}
              >
                {step === 4 ? "Submit" : "Next"}
              </button>
              
            </div>
          </form>
         
        </div>
        <div class="lg:max-w-md  lg:mt-28 lg:w-full md:hidden lg:block hidden ">
          <img
            class="object-cover  object-center rounded"
            alt="hero"
            src={bg2}
          />
        </div>
      </div>
      <RecentJob />
    </>
  );
};

export default SingupTeacher;
