import React from 'react'

function Appbanner() {
  return (
<section class="text-white bg-[#0f3057] body-font">
  <div class="container mx-auto flex px-5 py-[60px] items-center justify-center flex-col">
   
    <div class="text-center lg:w-2/3 w-full">
         <h1 class="title-font sm:text-4xl text-3xl mb-4 font-semibold text-white">Join Us in Shaping the  <span class="text-[#ffff] font-bold">Future of Education</span></h1>
         <p class=" leading-relaxed">Whether you're a teacher seeking a fulfilling career or a school looking to build a world-class faculty, <span class="text-[#11a9b5] font-semibold">MySchoolNaukri</span> is your trusted partner.<span  onClick={() =>
    window.open(
      "https://play.google.com/store/apps/details?id=com.myschoolnaukri.msn&pli=1",
      "_blank"
    )
  } class="text-[#11a9b5] cursor-pointer font-semibold">Download our app</span>   today and discover how we can help you achieve your educational goals.</p>

    {/* <div class="flex justify-around md:mr-0  items-center flex-shrink-0  -mt-10 -mb-10 ">
      <h1 class="md:text-3xl text-xl font-bold title-font text-[#11a9b5]">Download Our App</h1>
    <iframe src="https://lottie.host/embed/4b1f90c2-aaeb-4f78-95a7-a2fdef30f9ac/tFSQsUGqmF.lottie" className=" bg-black rounded-xl" height={80} width={150}></iframe>  
      </div> */}
 
      
    </div>
  </div>
</section>  )
}

export default Appbanner