import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Paginationa from "../components/Paginationa";
import { useAuth } from "../../Utils/Context";
import Loading from "../../Utils/Loading";
import JobPopupModal from "../components/JobviewModal";
export default function Employer() {
    
    const [page, setPage] = useState(1);
    const {api} = useAuth()
    // const token = localStorage.getItem("token");
    // console.log(token)
    const fetchCandidates = async (page) => {
        const token = localStorage.getItem("token");
        try {
          const response = await api.get(
            `/api/admin/employer?page=${page}`,
            {}, // Post body, if required
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
      
          return response.data; // Return the data property from Axios response
        } catch (error) {
          console.error("Failed to fetch data:", error);
          throw new Error("Failed to fetch Employers");
        }
      };
      
  // Fetch data with React Query and pass page as a dependency
  const { data, isLoading, isError  , error} = useQuery({queryKey:["candidates", page], queryFn:() => fetchCandidates(page), 
    keepPreviousData: true, // Retain previous page data while loading new page
  });

  // Handle loading and error states
  if (isLoading) return <Loading/>;
  if (isError) return <div className='text-center text-2xl text-red-500'>Error: {error.message}</div>;

  const candidates = data?.data || []; // Access the candidates array
  const totalCandidates = data?.total || 0;  // Total number of candidates
  const totalPages = Math.ceil(totalCandidates / 10); // Assuming 10 items per page
  return (
    

<div class="relative overflow-x-auto shadow-md sm:rounded-lg px-4 py-10">
<h2 className='mb-4 font-bold text-xl px-2'>Employer Report</h2>
<div className="overflow-x-auto">
       <table className="w-full text-sm text-left text-gray-500">
         <thead className="text-xs text-gray-700 uppercase bg-gray-200">
           <tr className="px-2 py-2">
             <th className="p-4 ">S.No.</th>
             <th className="px-6 py-3">Name</th>
             <th className="px-6 py-3">mobile</th>
             <th className="px-6 py-3">Email</th>
             <th className="px-6 py-3">School/Org </th>
             <th className="px-6 py-3">Total Jobs Posted </th>
             <th className="px-6 py-3">View jobs </th>
             <th className="px-6 py-3">Date</th>
             <th className="px-6 py-3">Address</th>
           </tr>
         </thead>
         <tbody>
           {candidates.map((candidate, index) => (
             <tr key={candidate.id} className="bg-white border-b  hover:bg-gray-200">
               <td className="p-4 border border-gray-300">{(page - 1) * 10 + index + 1}</td>
               <td className="px-3 py-4 font-medium text-gray-900 border border-gray-300">{candidate.name}</td>
               <td className="px-3 py-4 border border-gray-300">{candidate.mobile}</td>
               <td className="px-3 py-4 border border-gray-300">{candidate.email}</td>
               <td className="px-2 py-1 border border-gray-300">
                {candidate.org_name}
               </td>
               <td className="px-3 py-1 border border-gray-300">
                {candidate.total_jobs_posted?? "N/A"}
               </td>
               <td className="px-3 py-1 border border-gray-300">
                    <JobPopupModal jobData={candidate}/>
                  </td>
               <td className="px-2 py-2 border border-gray-300">
                 {candidate.max_created_at?.slice(0,10)}
               </td>
               <td className="ps-5  px-1 py-1 border border-gray-300">
                 {candidate.address || "N/A"}
               </td>
             </tr>
           ))}
         </tbody>
       </table>
 
     
      <Paginationa page={page} totalCandidates={totalCandidates} setPage={setPage} totalPages={totalPages}/>
     </div>
  
</div>

  )
}
