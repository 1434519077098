import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import '../index.css'

function Card({title, desc}) {
  const [jobs, setJobs] = useState([]);
  const [laoding, setlaoding] = useState(false);
  const navigate = useNavigate();
  const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

  // Fetch jobs from the API
  useEffect(() => {
    const fetchJobs = async () => {
      setlaoding(true)
      try {
        const response = await axios.get(`${baseApiUrl}/api/jobs_list`);
        setJobs(response.data.slice(0, 6)); // Get first 6 jobs
        setlaoding(false)
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };
    fetchJobs();
  }, []);

  if(laoding) return <Loading/>
  // Navigate to the job details page when a card is clicked
  const handleCardClick = (jobId) => {
    navigate(`/job/${jobId}`);
  };
  
   
  return (
      <>
     
      
      
     <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-6 gap-4  lg:px-8">
      {jobs.map((job) => (
    <div  key={job.id}
    onClick={() => handleCardClick(job.id)} class="lg:max-w-2xl px-0 lg:px-8 lg:py-4 py-1 bg-[#d9f1f3] rounded-lg shadow-md hover:scale-105 transition-transform ">
    

    <div class="mt-2 px-4">
        <p href="/" class="lg:text-xl text-md sm:font-bold font-semibold text-[#0f3057]  hover:text-gray-600  hover:underline" tabindex="0" role="link">{job.name}</p>
        <p href="/" class="lg:text-lg text-sm sm:font-bold font-semibold text-[#0f3057]  hover:text-gray-600  hover:underline" tabindex="0" role="link">{job.school_name}</p>
        <div className="flex flex-wrap">
        <div class="flex items-center  mt-1 text-[#11a9b5] ">
            <svg aria-label="suitcase icon" class="sm:w-6 sm:h-6 h-3 w-3   fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 11H10V13H14V11Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7 5V4C7 2.89545 7.89539 2 9 2H15C16.1046 2 17 2.89545 17 4V5H20C21.6569 5 23 6.34314 23 8V18C23 19.6569 21.6569 21 20 21H4C2.34314 21 1 19.6569 1 18V8C1 6.34314 2.34314 5 4 5H7ZM9 4H15V5H9V4ZM4 7C3.44775 7 3 7.44769 3 8V14H21V8C21 7.44769 20.5522 7 20 7H4ZM3 18V16H21V18C21 18.5523 20.5522 19 20 19H4C3.44775 19 3 18.5523 3 18Z"/>
            </svg>

            <h1 class="sm:px-2 px-1  sm:text-sm text-[14px] text-gray-600">Full time </h1>
        </div>
        <div class="flex items-center mt-1 text-[#11a9b5] ">
            <svg aria-label="location pin icon" class="sm:w-6 sm:h-6 h-3 w-3  fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2721 10.2721C16.2721 12.4813 14.4813 14.2721 12.2721 14.2721C10.063 14.2721 8.27214 12.4813 8.27214 10.2721C8.27214 8.063 10.063 6.27214 12.2721 6.27214C14.4813 6.27214 16.2721 8.063 16.2721 10.2721ZM14.2721 10.2721C14.2721 11.3767 13.3767 12.2721 12.2721 12.2721C11.1676 12.2721 10.2721 11.3767 10.2721 10.2721C10.2721 9.16757 11.1676 8.27214 12.2721 8.27214C13.3767 8.27214 14.2721 9.16757 14.2721 10.2721Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M5.79417 16.5183C2.19424 13.0909 2.05438 7.3941 5.48178 3.79418C8.90918 0.194258 14.6059 0.0543983 18.2059 3.48179C21.8058 6.90919 21.9457 12.606 18.5183 16.2059L12.3124 22.7241L5.79417 16.5183ZM17.0698 14.8268L12.243 19.8965L7.17324 15.0698C4.3733 12.404 4.26452 7.9732 6.93028 5.17326C9.59603 2.37332 14.0268 2.26454 16.8268 4.93029C19.6267 7.59604 19.7355 12.0269 17.0698 14.8268Z"/>
            </svg>

            <h1 class="sm:px-2 px-1  sm:text-sm text-[14px] text-gray-600">{job.state_name} , {job.city_name} </h1>
        </div>
        </div>
        <p class="mt-2 text-gray-600 truncate-multi-line  text-[14px] sm:text-sm px-1">{job.job_description || "Description is not Provided here"}  </p>
        {/* <p class="mt-2 text-gray-600  md:hidden truncate-multi-line text-[14px] px-1 ">{job.job_description.substring(0, 100)}  </p> */}
    </div>
    <div className="px-4 ">

   
    <div class="flex items-center   border-t-2 border-[#0f3057] justify-between  mt-4">
    <div class="flex items-center mt-3  text-[#11a9b5] ">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="sm:w-6 sm:h-6 h-3 w-3   fill-current">  <path fill="#11a9b5" d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-224c0-35.3-28.7-64-64-64L80 128c-8.8 0-16-7.2-16-16s7.2-16 16-16l368 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L64 32zM416 272a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>

            <h1 class="px-2 sm:text-sm text-[14px] text-gray-600"> ₹ {parseInt(job.salarymax)*12} (P. A)</h1>
        </div>

        <div class="flex mt-3 items-center">
        <p class="px-4 sm:px-6 sm:py-2 py-1 lg:text-sm text-[14px] sm:font-bold font-semibold mb-2 text-gray-100 transition-colors duration-300 transform bg-[#0f3057] rounded cursor-pointer hover:bg-gray-500" tabindex="0" role="button">View Job</p>
        </div>
    </div>
    </div>
</div>
  ))}
  </div>

     </>
  )
}

export default Card