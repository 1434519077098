import { useLocation ,useNavigate} from "react-router-dom";
import { useEffect, useState } from "react";
import Pageination from "../../dashboard/components/Pageination";
import Jobcardc from "../components/Jobcardc";
import SkeletonCard from "../../dashboard/components/Skeleton";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../Utils/Context";
import JobfilterC from "../components/JobfilterC";
import { fetchCandidates } from "../components/Function";
import "react-toastify/dist/ReactToastify.css";

function Candidatejobs() {
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const jobsPerPage = 10;
  const { api, setusername } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token"); // Replace with your actual Bearer token
      try {
        const response = await api.get(
          "/api/candidate/view_candidate_profile",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.success) {
          // console.log(response.data);

          setusername(response.data.candidate_profile?.first_name);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchData();
  }, []);


 

  const navigate = useNavigate();

  // Initialize filters from localStorage or defaults
  const initialFilters = JSON.parse(localStorage.getItem("filters")) || {
    designation: null,
    experience: 0,
    location: null,
    workMode: null,
    education: null,
    specialization: null,
  };

  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    // Load filters from URL parameters or defaults
    const queryParams = new URLSearchParams(location.search);
    const loadedFilters = {
      designation: queryParams.get("designation") || initialFilters.designation,
      experience: queryParams.get("experience") || initialFilters.experience,
      location: queryParams.get("location") || initialFilters.location,
      workMode: queryParams.get("workMode") || initialFilters.workMode,
      education: queryParams.get("education") || initialFilters.education,
      specialization: queryParams.get("specialization") || initialFilters.specialization,
    };

    // Set filters based on URL parameters and localStorage
    setFilters(loadedFilters);
  }, [location.search]);

  useEffect(() => {
    // Update filters in localStorage on change
    localStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]);


  // Fetch jobs from the API

  const {
    data: candidate = [],
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["jobs", { currentPage, filters }],
    queryFn: () => fetchCandidates(currentPage, filters),

    keepPreviousData: true, // Keep the previous data while loading new data
  });
  const handleFilterChange = (newFilters) => {
    // Update filters and reflect in React Select component
    setFilters(newFilters);
  };  
  // const candidate = data.data || [];

  if (isLoading) return <SkeletonCard />;
  if (!candidate.data)
    return (
      <div className="text-center text-2xl text-red-500">
        Unable to Load data, Please try again 
      </div>
    );
  // if (isError) return <div className='text-center text-2xl text-red-500'>Error: {error.response.status === 401?"please Login again" : error.message}</div>;

  return (
    <>
     
      <div className="mb-4 mt-2  justify-center   sm:bg-slate-50 flex flex-wrap border-gray-600 ">
        <div className="grid sm:grid-cols-3 sm:gap-20 mt-0 sm:mt-8  mb-4">
          <div className="col-span-1     mt-[0px] w-full sm:px-0  ml-[10px] sm:ml:-[230px] sm:ml-0 sm:mt-0  sm:z-0 z-30">
            <JobfilterC filters={filters} onChange={handleFilterChange} />
            <div className="my-4 hidden sm:block ">
              {filters.location || filters.designation || filters.job_type || filters.experience || filters.salary_range || filters.job_category ? (

                <h3 className="sm:text-lg text-sm text-[#11a9b5]  font-bold">
                Selected Filters:
              </h3>
              ):""}
              <div>
                {filters.designation && (
                  <div class="lg:text-lg text-xs  text-[#0f3057] ">
                    Designation: {filters.designation.label}
                  </div>
                )}
                {filters.location && (
                  <div class="lg:text-lg text-xs  text-[#0f3057] ">
                    Location: {filters.location.label}
                  </div>
                )}
                {filters.experience>0 && (
                  <div class="lg:text-lg text-xs  text-[#0f3057] ">
                    Experience: {filters.experience} years
                  </div>
                )}
                {filters.education && (
                  <div class="lg:text-lg text-xs  text-[#0f3057] ">
                    Education: {filters.education.label}
                  </div>
                )}
                {filters.workMode && (
                  <div class="lg:text-lg text-xs  text-[#0f3057] ">
                    Work Mode: {filters.workMode.label}
                  </div>
                )}
                {filters.specilization && (
                  <div class="lg:text-lg text-xs  text-[#0f3057] ">
                    Specialization: {filters.specilization.label}
                  </div>
                )}
              </div>
            </div>
          </div>
          {candidate.data.length > 0 ? (
            <div className="space-y-4 mx- sm:mx-0 sm:mt-0 mt-12 col-span-2">
              {candidate.data.map((job) => (
                <Jobcardc
                  key={job.id}
                  id={job.id}
                  state_name={job.state}
                  name={job.designation}
                  school_name={job.school_name}
                  city_name={job.location}
                  job_description={job.job_description}
                  salarymax={job.salarymax}
                  salarymin={job.salarymin}
                  date={job.max_created_at}
                  applicant={job.count}
                  employment_type={job.employment_type}
                />
              ))}

              <Pageination
                data={candidate.data}
                firstpage={candidate.first_page_url}
                totalpage={candidate.total}
                lastpage={candidate.last_page_url}
                Links={candidate.links}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                jobsPerPage={jobsPerPage}
              />
            </div>
          ) : (
            <p class="lg:text-lg  text-red-500  font-semibold">
              No jobs found, Apply different filter
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default Candidatejobs;
