import React from "react";
import SubscriptionOverPage from "../../pages/Subscriptionover";

const SubscriptionPlan = ({subactive}) => {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
        {subactive?.status ===1 ? <div className="-mt-40" >
            <SubscriptionOverPage />
        </div>
     : <div className="max-w-4xl w-full bg-white shadow-lg rounded-lg p-6">
        {/* Header Section */}
        <div className="text-center border-b pb-6">
          <h1 className="text-2xl font-bold text-gray-800">
            Current Subscription Plan
          </h1>
          <h2 className="text-2xl text-[#11a9b5] mt-2">{subactive.response[0]?.subscription_plan}</h2>
          <p className="text-xl text-gray-600 mt-1">Validity: {subactive.response[0]?.valid_upto} months</p>
        </div>

        {/* Features Section */}
        <div className="mt-6">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">
             This plan includes:
          </h3>
          <ul className="space-y-3">
              <li
                className="flex items-center space-x-3 hover:bg-gray-200 bg-gray-100 p-3 rounded-lg shadow-sm"
              >
                <div className="w-8 h-8 flex items-center justify-center bg-[#11a9b5] text-white rounded-full">
                  ✓
                </div>
                <span className="text-gray-700">{subactive.response[0]?.total_job_post} job postings</span>
              </li>
              <li
                className="flex items-center space-x-3 hover:bg-gray-200 bg-gray-100 p-3 rounded-lg shadow-sm"
              >
                <div className="w-8 h-8 flex items-center justify-center bg-[#11a9b5] text-white rounded-full">
                  ✓
                </div>
                <span className="text-gray-700">{subactive.response[0]?.total_resume_download} CV Downloads</span>
              </li>
              <li
                className="flex items-center space-x-3 hover:bg-gray-200 bg-gray-100 p-3 rounded-lg shadow-sm"
              >
                <div className="w-8 h-8 flex items-center justify-center bg-[#11a9b5] text-white rounded-full">
                  ✓
                </div>
                <span className="text-gray-700">{subactive.response[0]?.data_access_validity} months access to database</span>
              </li>
              <li
                className="flex items-center space-x-3 hover:bg-gray-200 bg-gray-100 p-3 rounded-lg shadow-sm"
              >
                <div className="w-8 h-8 flex items-center justify-center bg-[#11a9b5] text-white rounded-full">
                  ✓
                </div>
                <span className="text-gray-700">{subactive.response[0]?.email_contact_infor===1? "Emails & Contact Info ":""} </span>
              </li>
              <li
                className="flex items-center space-x-3 hover:bg-gray-200 bg-gray-100 p-3 rounded-lg shadow-sm"
              >
                <div className="w-8 h-8 flex items-center justify-center bg-[#11a9b5] text-white rounded-full">
                  ✓
                </div>
                <span className="text-gray-700">{subactive.response[0]?.in_built_ats===1? "In built ATS":""} </span>
              </li>
              <li
                className="flex items-center space-x-3 hover:bg-gray-200 bg-gray-100 p-3 rounded-lg shadow-sm"
              >
                <div className="w-8 h-8 flex items-center justify-center bg-[#11a9b5] text-white rounded-full">
                  ✓
                </div>
                <span className="text-gray-700">{subactive.response[0]?.tech_support_status===1? "Tech Support":""} </span>
              </li> 
          </ul>
        </div>

        {/* Footer Section */}
       
      </div>}
    </div>
  );
};

export default SubscriptionPlan;
